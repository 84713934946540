import React, {useEffect, useState} from "react";
import Resources from "../../../../data/services/resources";
import {createResource, deleteResource, getResource, updateResource} from "../../../../data/actions/resource";
import {getUser} from "../../../../util/util";
import {classNames, getProp} from "../../../../common/util/util-helpers";
import LocalStorage from "../../../../util/localStorage";
import {toFrontDateTime, toFrontDateTimeFromUTC} from "../../../../common/util/util-dates";
import {Field} from "../../../../data/services/fields";
import {PencilIcon, PlusIcon} from "@heroicons/react/24/outline";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import {LoaderLarge} from "../../../../common/components/loader";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";


export default function WorkOrdersNotesTab({ID, resource, dispatch, translate, isLoading}) {

    /** Constants
     ================================================================= */
    const getResourcePath = () => Resources.WorkOrderNotes;

    const query = Object.assign({
        sort: 'DESC',
        sortBy: 'CreateDateTime'
    }, {searchFields: JSON.stringify({WorkOrderID: ID})})
    const fetchData = (dispatch) => {
        dispatch(getResource({
            user: getUser(),
            resource: getResourcePath(),
            query: query
        }))
    }

    const data = getProp(resource.data, "list", []);
    const count = getProp(resource.data, "count", 0)
    const [userNameData, setUserNameData] = useState({});

    useEffect(() => {
        fetchData(dispatch);
    }, [])

    const [selectedItem, setSelectedItem] = useState({});
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [confirmDialogData, setConfirmDialogData] = useState({});

    const handleToggleCreateModal = (selectedItem = null) => {
        setSelectedItem(selectedItem);
        setCreateModalOpen(!createModalOpen);
    }

    const handleToggleEditModal = (selectedItem = null) => {
        setSelectedItem(selectedItem);
        setEditModalOpen(!editModalOpen);
    }


    const getFields = (item = {}) => {
        let fieldTemplates = {
            Title: new Field('Title', '', ['empty'], false, "text", {addContainerClass: "col-span-full"}),
            Notes: new Field('Notes', '', ['empty'], false, "textarea", {addContainerClass: "col-span-full"}),
        }

        fieldTemplates = fillFieldsFromData(fieldTemplates, item);

        return fieldTemplates;
    }

    const closeConfirmDialog = () => {
        setConfirmDialogData(Object.assign({}, confirmDialogData, {isOpen: false}))
    }

    const handleDeleteDocumentClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeleteDocumentClick(item),
            type: "danger",
            content: <span>
                Are you sure you want to delete this note?
            </span>,
            title: translate("Confirm delete note"),
            buttonLabel: translate("btn.delete")
        })
    }

    const handleConfirmDeleteDocumentClick = (item) => {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: Object.assign(query, {WorkOrderNotesID: item.WorkOrderNotesID}),
            resource: getResourcePath(),
            piggyResource: getResourcePath(),
            successMessage: translate('message.notes_deleted'),
            errorMessage: true
        }));

        closeConfirmDialog();
    }
    /** Component Body
     ================================================================= */
    return (
        <div>
            {/*<div className={"text-3xl text-base text-tm-gray-900 ml-20 mb-4 mt-4"}>Notes</div>*/}

            {isLoading && (
                <div className="items-center ml-20">
                    <LoaderLarge
                        disableContainer
                    />
                </div>
            )}

            {!isLoading && (
                <ol className={classNames(data[0] ? 'border-l' : undefined, "relative border-gray-200 ml-20")}>

                    <button onClick={() => {
                        handleToggleCreateModal();
                    }}
                            className="relative flex items-center px-4 py-2 m-4 text-sm font-medium text-inverse bg-primary border border-gray-200 rounded-lg  hover:text-tm-gray-900">
                        <PlusIcon className={"w-5 h-5"}></PlusIcon>
                        Create new note
                    </button>

                    {data.map((it, index) => {
                        return (
                            <li key={data} className="mb-10 ml-6">
        <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white
        ">
            <svg className="w-2.5 h-2.5 text-blue-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 fill="currentColor" viewBox="0 0 20 20">
                <path
                    d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
            </svg>
        </span>
                                <h3 className="flex items-center mb-1 text-lg font-semibold text-tm-gray-900 ">{it.Title} {index === 0 &&
                                    <span
                                        className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded  ml-3">Latest</span>}</h3>
                                <time
                                    className="block mb-2 text-sm font-normal leading-none text-gray-400">{toFrontDateTimeFromUTC(it.CreateDateTime)} by {it.CreatedByContact}</time>
                                <p className="mb-4 text-base font-normal text-gray-500">{it.Notes}</p>


                                {(it.CreatedByContactID === getUser('Contact.ContactID')) && (
                                    <button
                                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10"
                                        onClick={() => {
                                            handleToggleEditModal(it);
                                        }}
                                    >
                                        <PencilIcon className={"w-6 h-6 p-1"}></PencilIcon>
                                        Edit
                                    </button>
                                )}

                                {(it.CreatedByContactID === getUser('Contact.ContactID')) && (
                                    <button
                                        className="inline-flex items-center ml-4 px-4 py-2 text-sm font-medium text-white bg-red-700 border border-gray-200 rounded-lg hover:bg-red-700 hover:text-tm-gray-900"
                                        onClick={() => handleDeleteDocumentClick(it)}
                                    >
                                        <TrashIcon className={"w-6 h-6 p-1"}></TrashIcon>
                                        Delete </button>
                                )}
                            </li>
                        )
                    })}
                </ol>
            )}

            {!data[0] && (<div className={"ml-20 text-base text-3xl"}>Notes are empty</div>)}


            <ModalSaveResource
                show={createModalOpen}
                fields={getFields()}

                //onInputChange={handleInputChange}
                title={translate("title.create_note")}
                onClose={() => {
                    setCreateModalOpen(false)
                    setSelectedItem({})
                }}
                onSubmit={(params) => {

                    if (!!params) {
                        params.WorkOrderID = ID;
                        dispatch(createResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            query: Object.assign({
                                sort: 'DESC',
                                sortBy: 'CreateDateTime'
                            }, {searchFields: JSON.stringify({WorkOrderID: ID})}),
                            resource: getResourcePath(),
                            piggyResource: getResourcePath(),
                            successMessage: translate("message.note_created"),
                            errorMessage: true
                        }))


                        setSelectedItem(params); // in case of server error save form data
                        setCreateModalOpen(false)

                    }
                }}
                addFieldContainerClass={"col-span-full"}
                addBodyClass={"pb-48 text-tm-gray-900"}
                isLoading={isLoading}
                translate={translate}
            />

            <ModalSaveResource
                show={editModalOpen}
                fields={getFields(selectedItem)}

                //onInputChange={handleInputChange}
                title={selectedItem?.Title}
                onClose={() => {
                    setEditModalOpen(false)
                    setSelectedItem({})
                }}
                onSubmit={(params) => {

                    if (!!params) {
                        params.WorkOrderID = ID;
                        params.WorkOrderNotesID = selectedItem.WorkOrderNotesID;
                        dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            query: Object.assign({
                                sort: 'DESC',
                                sortBy: 'CreateDateTime'
                            }, {searchFields: JSON.stringify({WorkOrderID: ID})}),
                            resource: getResourcePath(),
                            piggyResource: getResourcePath(),
                            successMessage: translate("message.note_created"),
                            errorMessage: true
                        }))


                        setSelectedItem(params); // in case of server error save form data
                        setEditModalOpen(false)

                    }
                }}
                addFieldContainerClass={"col-span-full"}
                addBodyClass={"pb-48 text-tm-gray-900"}
                isLoading={isLoading}
                translate={translate}
            />

            <ModalConfirm
                show={confirmDialogData?.isOpen ?? false}
                type={confirmDialogData?.type}
                content={confirmDialogData?.content}
                title={confirmDialogData?.title}
                onConfirm={confirmDialogData?.onClick}
                closeButtonLabel={confirmDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                buttonLabel={confirmDialogData?.buttonLabel}
                translate={translate}
                onClose={closeConfirmDialog}
            />
        </div>

    )
}