import React, {useEffect, useState} from "react";
import {Field, FieldsManager} from "../../../data/services/fields";
import {checkPerm, resourceIsUpdated} from "../../../common/util/util-helpers";
import {getUser} from "../../../common/util/util-auth"
import Resources from "../../../data/services/resources";
import {READ_PERM, UPDATE_PERM} from "../../../common/util/util-consts";
import InformationCircleIcon from "@heroicons/react/20/solid/esm/InformationCircleIcon";
import {showModal} from "../../../data/actions/ui";
import {useDispatch, useSelector} from "react-redux";
import {getResource, updateResource} from "../../../data/actions/resource";
import LocalStorage from "../../../common/util/localStorage";
import {classNames} from "../../../common/util/util-helpers";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import PageTabWithFooter from "../../../common/components/layout/layout-components/page/page-tab-with-footer";
import FieldsToHtml from "../../../common/components/fields/fields-to-html";
import {LoaderLarge} from "../../../common/components/loader";
import FieldOptions from "../../../common/components/fields/field-options";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";

export default function RentalContractsSingleInfoTab({translate, id, fetchData}) {

    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = resource.isLoading ?? false;
    const resourcePath = Resources.WorkOrdersRentalContracts;
    const [isStateDirty, setIsStateDirty] = useState(false);
    const [fields, setFields] = useState(getFields({}));

    /** Data Events
     ================================================================= */
    function fetchTabData() {
        dispatch(getResource({
            user: getUser(),
            resource: Resources.WorkOrdersRentalContract,
            query: {id: id}
        }));
    }

    function handleSubmitClick() {
        const validatedFields = FieldsManager.validateFields(fields);
        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            const params = FieldsManager.getFieldKeyValues(validatedFields);
            params.id = id;
            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: params,
                query: {
                    id: id
                },
                resource: resourcePath,
                piggyResource: Resources.WorkOrdersRentalContract,
                successMessage: translate("text.rental_contract_updated"),
            }));
        } else {
            setFields(Object.assign({}, validatedFields))
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    /** UI Events
     ================================================================= */
    function handleInputChange(name, value) {
        let fieldsClone = fields;
        fieldsClone = FieldsManager.updateField(fieldsClone, name, value);
        fieldsClone[name].errorMessage = "";

        if (name === 'RentTo') {
            if (value === 1) {
                fieldsClone['RentOrganizationID'].value = ''
                fieldsClone['RentOrganizationID'].disabled = true
                fieldsClone['RentOrganizationID'].validate = []

                fieldsClone['RentContactID'].disabled = false
            }
            if (value === 2) {
                fieldsClone['RentContactID'].value = ''
                fieldsClone['RentContactID'].disabled = true
                fieldsClone['RentContactID'].validate = []

                fieldsClone['RentOrganizationID'].disabled = false
            }
        }

        setFields(Object.assign({}, fieldsClone));
        setIsStateDirty(true);
    }

    const handleViewContactClick = (contactID) => {
        dispatch(showModal("ViewContactCard", {ContactID: contactID}))
    }

    const handleContactInfoClick = (contactID) => {
        dispatch(showModal("ViewContactCard", {ContactID: contactID}))
    }

    const handleOrganizationInfoClick = (item) => {
        if (item.CarrierID) {
            dispatch(showModal('ViewCarrierCard', {CarrierID: item?.CarrierID, OrganizationID: item?.OrganizationID}))
        }

        if (item.CustomerID) {
            dispatch(showModal('ViewCustomerCard', {CustomerID: item?.CustomerID, OrganizationID: item?.OrganizationID}))
        }

        if (item.VendorID) {
            dispatch(showModal('ViewVendorCard', {VendorID: item?.VendorID, OrganizationID: item?.OrganizationID}))
        }
    }


    /** Helper functions
     ================================================================= */
    function getFields(item = {}) {
        const fieldTemplates = {
            AutoCounter: new Field('AutoCounter', '', [], false, 'integer', {hideDialog: true}),
            RefNumber: new Field('RefNumber', '', [], false, 'text', {addContainerClass: "col-span-full"}),
            RentTo: new Field('RentTo', '1', [], false, 'button-group', {
                data: {1: translate('btn.contact'), 2: translate('btn.organization')},
                addContainerClass: 'col-span-full',
                render: (item) => {
                    if (item.RentContactID) {
                        return (
                            <CellButton
                                onClick={() => checkPerm(Resources.Contacts, UPDATE_PERM) && handleViewContactClick(item.RentContactID)}
                            >
                                {item.RentContact}
                            </CellButton>
                        )
                    } else if (item.RentOrganizationID) {
                        return (
                            <CellButton
                                onClick={() => checkPerm(Resources.Organizations, READ_PERM) && handleOrganizationInfoClick(item)}
                            >
                                {item.RentOrganization}
                            </CellButton>
                        )
                    } else {
                        return ""
                    }
                }
                , omitSort: true
            }),
            RentContactID: new Field(
                'RentContactID',
                '',
                [''],
                !item.RentContactID,
                'select-search',
                {
                    hideTable: true,
                    addContainerClass: "col-span-6",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[{
                                    icon: InformationCircleIcon,
                                    onClick: () => handleContactInfoClick(it?.value?.value),
                                    isVisible: !!it?.value
                                }]}
                            />
                        )
                    }
                }, {
                    hasPortal: true,
                    menuPosition: 'fixed'
                }
            ),
            RentOrganizationID: new Field(
                'RentOrganizationID',
                '',
                [''],
                !item.RentOrganizationID,
                'select-search',
                {
                    hideTable: true,
                    addContainerClass: "col-span-6",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[{
                                    icon: InformationCircleIcon,
                                    onClick: () => handleOrganizationInfoClick(it?.value?.metadata),
                                    isVisible: !!it?.value
                                }]}
                            />
                        )
                    }
                }, {
                    hasPortal: true,
                    menuPosition: 'fixed'
                }
            ),
            StartDate: new Field('StartDate', '', [''], false, 'date',
                {hasActiveBadge: true, addContainerClass: 'col-span-6'},
                {
                    isClearable: true,
                    popperProps: {
                        strategy: 'fixed',
                        positionFixed: true
                    }
                }),
            EndDate: new Field('EndDate', '', [''], false, 'date',
                {hasActiveBadge: true, addContainerClass: 'col-span-6'},
                {
                    isClearable: true,
                    popperProps: {
                        strategy: 'fixed',
                        positionFixed: true
                    }
                }),
            Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }

        if (item) {
            if (item.RentContactID) {
                fieldTemplates['RentTo'].value = 1;
            }
            if (item.RentOrganizationID) {
                fieldTemplates['RentTo'].value = 2;
            }
        }

        return fillFieldsFromData(fieldTemplates, item)
    }


    /** Life Cycle
     ================================================================= */
    useEffect(() => {
        fetchTabData();
    }, [])

    useEffect(() => {
        if (resource?.data?.RentalContractID && !resource.isLoading) {
            setFields(getFields(resource.data))
        }

        if (resourceIsUpdated(resource)) {
            setIsStateDirty(false);
        }
    }, [resource]);
    /** Component Body
     ================================================================= */
    return (
        <PageTabWithFooter
            isLoading={isLoading}
            buttons={
                [
                    {
                        className: "btn btn-primary",
                        onClick: handleSubmitClick,
                        disabled: !isStateDirty,
                        label: translate("btn.save")
                    },
                    {
                        className: "btn btn-outline",
                        disabled: !isStateDirty,
                        onClick: () => {
                            setIsStateDirty(false)
                        },
                        label: translate("btn.cancel")
                    },
                ]
            }
        >

            <div className={
                classNames(
                    "p-6 grid grid-cols-12 gap-4 max-w-2xl mx-auto relative",
                    isLoading ? "border border-tm-gray-200 rounded-card" : undefined
                )}
            >
                {isLoading && (
                    <LoaderLarge stripesBg={true}/>
                )}
                <FieldsToHtml
                    fieldsState={fields}
                    onInputChange={handleInputChange}
                    selects={{
                        RentContactID: {
                            api: 'api/' + Resources.Contacts,
                            query: {},
                            searchMap: (it) => ({
                                label: `${it.FirstName} ${it.LastName}`,
                                value: it.ContactID,
                                metadata: it
                            })
                        },
                        RentOrganizationID: {
                            api: 'api/' + Resources.Organizations,
                            query: {},
                            searchMap: (it) => ({
                                label: it.LegalName,
                                value: it.OrganizationID,
                                metadata: it
                            })
                        },
                    }}
                    translate={translate}
                />
            </div>

        </PageTabWithFooter>
    )
}