import React, {Component} from "react";
import {createBrowserHistory} from "history";
import {Redirect, Route as NeutralRoute, Router, Switch} from "react-router-dom";
import Cookie from "./util/cookie";
import {_ENV_MODE} from "./util/env";
import Login from "./views/auth/login";
import NotFoundView from "./views/general/not-found";
import ResetPassword from "./views/auth/reset-password";
import ResetPasswordConfirm from "./views/auth/reset-password-confirm";
import ContactsView from "./views/contacts/contacts-view";
import GroupsView from "./views/my-company/groups-view";
import OfficesView from "./views/my-company/offices-view";
import DivisionsView from "./views/my-company/divisions-view";
import SystemUsersView from "./views/my-company/system-users-view";
import ContactPermissionsView from "./views/my-company/contact-permissions-view";
import CustomersView from "./views/companies/customers-view";
import VendorsView from "./views/companies/vendors";
import CustomerCreateNew from "./views/companies/customer-create";
import CustomerView from "./views/companies/customer";
import VendorCreateView from "./views/companies/vendor-create";
import VendorView from "./views/companies/vendor";
import AllTasksView from "./views/tasks/all-tasks";
import TasksSettingsView from "./views/tasks/tasks-settings-view";
import TaskGroupView from "./views/tasks/group-tasks";
import RepeatTasksView from "./views/tasks/repeatTasks";
import Resources from "./data/services/resources";
import ExpiryItemsView from "./views/general/expiry-items-view";
import Profiles from "./views/general/profiles";
import AccountView from "./views/general/account-view";
import LoggedDevicesView from "./views/general/logged-devices-view";
import TruckCreateView from "./views/fleet/truck-create";
import TrucksView from "./views/fleet/trucks-view";
import TruckView from "./views/fleet/truck";
import TrailersView from "./views/fleet/trailers";
import TrailerCreateView from "./views/fleet/trailer-create";
import TrailerView from "./views/fleet/trailer";
import FuelCardsView from "./views/fleet/fuel-cards-view";
import FuelPurchaseView from "./views/fleet/fuel-purchase-view";
import TranspondersView from "./views/fleet/transponders-view";
import FuelReportsView from "./views/fleet/fuel-reports-view";
import ReportsView from "./views/fleet/reports-view";
import MileageView from "./views/fleet/mileage-view";
import FleetSettingsView from "./views/fleet/fleet-settings-view";
import WorkOrdersView from "./views/work-orders/work-orders-view";
import WorkOrdersCreate from "./views/work-orders/work-orders-create";
import WorkOrdersSingleView from "./views/work-orders/work-orders-single-view";
import RentalsCreate from "./views/rent-lease/rentals-create";
import RentalsView from "./views/rent-lease/rentals-view";
import RentalsSingleView from "./views/rent-lease/rentals-single-view";
import RentalContractsView from "./views/rent-lease/rental-contracts-view";
import RentalContractsSingleView from "./views/rent-lease/rental-contracts-single-view";
import LocalStorage from "./common/util/localStorage";
import LoggingInView from "./views/general/logging-in";
import {getUser} from "./util/util-auth";
import {generateCustomNotification} from "./common/util/util-helpers";
import TaskGrabData from "./common/components/layout/layout-components/task-grab-data";
import AccidentsView from "./views/safety/accidents";
import AccidentsCreateView from "./views/safety/accidents-new";
import EmployeesPageView from "./views/personnel/employees-page";
import EmployeeSingleView from "./views/personnel/employee-single";
import AgentsView from "./views/personnel/agents-view";
import AgentSingleView from "./views/personnel/agent-single";
import Notifications from "./common/components/notifications";
import AccountingSummaryPageView from "./views/accounting/summary";
import IncomePageView from "./views/accounting/income/income-page";
import ExpensePageView from "./views/accounting/expense/expense-page";
import JournalView from "./views/accounting/journal";
import BillingView from "./views/accounting/customer-billing";
import PurchaseOrderSingleView from "./views/accounting/purchase-order-single";
import TaxesPage from "./views/accounting/taxes/taxes-page";
import AccountingSettingsView from "./views/accounting/accounting-settings";
import CarrierPayrollView from "./views/accounting/carrier-pay";
import PayrollView from "./views/accounting/driver-payroll";
import AccountingReimbursementPageView from "./views/accounting/accounting-reimbursement-view";
import AccountingReconciliationsView from "./views/accounting/reconciliation/reconciliations-view";
import CreditAccountsPageView from "./views/accounting/credit-accounts";
import TransactionDetailsView from "./views/accounting/credit-accounts/single-account-transaction-details";
import MoneyCodesPage from "./views/accounting/money-codes-view";
import ProductsServicesPageView from "./views/accounting/products-services";
import AccountingReconciliationView from "./views/accounting/reconciliation";
import AccountingPageView from "./views/accounting/chart-of-accounts/chart-of-accounts";
import AssetsInventoryPageView from "./views/accounting/assets-inventory";
import AccountingReportsPageView from "./views/accounting/reports/reports-page";
import PurchaseOrder from "./views/accounting/purchase-order-page";
import UiToolsView from "./views/general/settings/ui-tools-view";
import IntegrationsTab from "./views/general/settings/integrations";
import TicketsView from "./views/general/tickets-view";

const PrivateRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props => {
            const token = Cookie.getCookie("access_token_" + _ENV_MODE);

            if (token) {
                if (!LocalStorage.has("user")) {
                    LocalStorage.set("redirect_path", props.match.url);

                    return <LoggingInView {...props} />;
                } else if (getUser("access_token") !== token) {
                    return <LoggingInView {...props} />;
                }

                return <Component {...props} />;
            }

            LocalStorage.set("redirect_path", props.match.url);
            return (
                <Redirect to={{
                    pathname: "/login",
                    state: {from: props.location}
                }}/>
            )
        }}
    />
);

const PublicRoute = ({component: Component, ...rest}) => {
    const token = Cookie.getCookie("access_token_" + _ENV_MODE);

    return (
        <NeutralRoute
            {...rest}
            render={props =>
                (!token) ? (<Component {...props}/>) : (
                    <Redirect to={{
                        pathname: "/home",
                        state: {from: props.location}
                    }}
                    />
                )
            }
        />
    )
};

export default function Routes(store, translator) {
    function getComponent(Component, props) {
        return (
            <Component {...props} translate={(key, args) => translator.translate(key, args)}/>
        );
    }

    const history = createBrowserHistory();
    store.history = history;

    return (
        <Router history={history}>
            <Switch>
                <PrivateRoute exact path="/" component={(props) => getComponent(RentalsView, props)}/>
                <PublicRoute exact path="/logging-in" component={(props) => getComponent(LoggingInView, props)}/>
                <PrivateRoute exact path="/home" component={(props) => getComponent(RentalsView, props)}/>

                {/* Auth routes */}
                <PublicRoute exact path="/login" component={(props) => getComponent(Login, props)}/>
                <PublicRoute exact path="/reset-password"
                             component={(props) => getComponent(ResetPassword, props)}/>
                <NeutralRoute exact path="/reset-password-confirm/:email/:token/:is_new"
                              component={(props) => getComponent(ResetPasswordConfirm, props)}/>

                {/*/!* Accounting *!/*/}
                <PrivateRoute exact path="/accounting-summary"
                              component={(props) => getComponent(AccountingSummaryPageView, props)}/>
                <PrivateRoute exact path="/accounting-income"
                              component={(props) => getComponent(IncomePageView, props)}/>
                <PrivateRoute exact path="/accounting-expenses"
                              component={(props) => getComponent(ExpensePageView, props)}/>
                <PrivateRoute exact path="/accounting-reports"
                              component={(props) => getComponent(AccountingReportsPageView, props)}/>
                <PrivateRoute exact path="/accounting-billing"
                              component={(props) => getComponent(BillingView, props)}/>
                <PrivateRoute exact path="/accounting-purchase-order"
                              component={(props) => getComponent(PurchaseOrder, props)}/>
                <PrivateRoute exact path="/purchase-order/create"
                              component={(props) => getComponent(PurchaseOrderSingleView, props)}/>
                <PrivateRoute exact path="/purchase-order/:id"
                              component={(props) => getComponent(PurchaseOrderSingleView, props)}/>

                <PrivateRoute exact path="/accounting-products-services"
                              component={(props) => getComponent(ProductsServicesPageView, props)}/>
                <PrivateRoute exact path="/accounting-assets-inventory"
                              component={(props) => getComponent(AssetsInventoryPageView, props)}/>
                <PrivateRoute exact path="/accounting-taxes"
                              component={(props) => getComponent(TaxesPage, props)}/>
                <PrivateRoute exact path="/accounting-journal"
                              component={(props) => getComponent(JournalView, props)}/>
                <PrivateRoute exact path="/accounting-accounts"
                              component={(props) => getComponent(AccountingPageView, props)}/>
                <PrivateRoute exact path="/driver-payroll"
                              component={(props) => getComponent(PayrollView, props)}/>
                <PrivateRoute exact path="/carrier-pay"
                              component={(props) => getComponent(CarrierPayrollView, props)}/>
                <PrivateRoute exact path="/accounting-settings"
                              component={(props) => getComponent(AccountingSettingsView, props)}/>
                <PrivateRoute exact path="/accounting-reimbursement"
                              component={(props) => getComponent(AccountingReimbursementPageView, props)}/>
                <PrivateRoute exact path="/accounting/reconciliations"
                              component={(props) => getComponent(AccountingReconciliationsView, props)}/>
                <PrivateRoute exact path="/accounting/reconciliation/:id"
                              component={(props) => getComponent(AccountingReconciliationView, props)}/>
                <PrivateRoute exact path="/accounting-credit-cards"
                              component={(props) => getComponent(CreditAccountsPageView, props)}/>
                <PrivateRoute exact path="/accounting/credit-cards/:id"
                              component={(props) => getComponent(TransactionDetailsView, props)}/>
                <PrivateRoute exact path="/money-codes"
                              component={(props) => getComponent(MoneyCodesPage, props)}/>

                {/* Contact routes */}
                <PrivateRoute exact path="/contacts" component={(props) => getComponent(ContactsView, props)}/>

                {/* My Company routes */}
                <PrivateRoute exact path="/offices" component={(props) => getComponent(OfficesView, props)}/>
                <PrivateRoute exact path="/groups" component={(props) => getComponent(GroupsView, props)}/>
                <PrivateRoute exact path="/divisions" component={(props) => getComponent(DivisionsView, props)}/>
                <PrivateRoute exact path="/system-users"
                              component={(props) => getComponent(SystemUsersView, props)}/>
                <PrivateRoute exact path="/contacts/:id"
                              component={(props) => getComponent(ContactPermissionsView, props)}/>


                {/* Locations routes */}
                {/*<PrivateRoute exact path="/locations" component={(props) => getComponent(LocationsView, props)}/>*/}
                {/*<PrivateRoute exact path="/location-zones"*/}
                {/*              component={(props) => getComponent(LocationZonesView, props)}/>*/}
                {/*<PrivateRoute exact path="/locations/duplicates"*/}
                {/*              component={(props) => getComponent(DuplicatesView, props)}/>*/}

                {/*Task routes*/}
                <PrivateRoute exact path="/all-tasks" component={(props) => getComponent(AllTasksView, props)}/>
                <PrivateRoute exact path="/board-tasks" component={(props) => getComponent(TaskGroupView, props)}/>
                <PrivateRoute exact path="/repeat-tasks"
                              component={(props) => getComponent(RepeatTasksView, props)}/>
                <PrivateRoute exact path="/tasks-settings"
                              component={(props) => getComponent(TasksSettingsView, props)}/>


                {/* Companies routes */}
                <PrivateRoute exact path="/customers" component={(props) => getComponent(CustomersView, props)}/>
                <PrivateRoute exact path="/customer/create"
                              component={(props) => getComponent(CustomerCreateNew, props)}/>
                <PrivateRoute exact path="/vendors" component={(props) => getComponent(VendorsView, props)}/>
                {/*<PrivateRoute exact path="/carriers" component={(props) => getComponent(CarriersView, props)}/>*/}
                {/*<PrivateRoute exact path="/carrier/create"*/}
                {/*              component={(props) => getComponent(CarrierCreateView, props)}/>*/}
                {/*<PrivateRoute exact path="/carriers/info/:OrganizationID/:id"*/}
                {/*              component={(props) => getComponent(CarrierView, props)}/>*/}
                <PrivateRoute exact path="/customers/info/:OrganizationID/:CustomerID"
                              component={(props) => getComponent(CustomerView, props)}/>
                <PrivateRoute exact path="/companies-expiry-items"
                              component={(props) => getComponent(ExpiryItemsView, Object.assign({}, props, {resourceName: Resources.CompaniesExpiry}))}/>

                <PrivateRoute exact path="/vendor/create"
                              component={(props) => getComponent(VendorCreateView, props)}/>
                <PrivateRoute exact path="/vendors/info/:OrganizationID/:VendorID"
                              component={(props) => getComponent(VendorView, props)}/>

                {/*Fleet*/}
                <PrivateRoute exact path="/trucks" component={(props) => getComponent(TrucksView, props)}/>
                <PrivateRoute exact path="/truck/create"
                              component={(props) => getComponent(TruckCreateView, props)}/>
                <PrivateRoute exact path="/trucks/info/:id" component={(props) => getComponent(TruckView, props)}/>
                <PrivateRoute exact path="/trailers" component={(props) => getComponent(TrailersView, props)}/>
                <PrivateRoute exact path="/trailer/create"
                              component={(props) => getComponent(TrailerCreateView, props)}/>
                <PrivateRoute exact path="/trailers/info/:id"
                              component={(props) => getComponent(TrailerView, props)}/>
                <PrivateRoute exact path="/fuel-cards" component={(props) => getComponent(FuelCardsView, props)}/>
                <PrivateRoute exact path="/fuel-purchase"
                              component={(props) => getComponent(FuelPurchaseView, props)}/>
                <PrivateRoute exact path="/transponders"
                              component={(props) => getComponent(TranspondersView, props)}/>
                <PrivateRoute exact path="/fuel-report"
                              component={(props) => getComponent(FuelReportsView, props)}/>
                <PrivateRoute exact path="/reports" component={(props) => getComponent(ReportsView, props)}/>
                <PrivateRoute exact path="/mileage" component={(props) => getComponent(MileageView, props)}/>
                <PrivateRoute exact path="/expiry-items"
                              component={(props) => getComponent(ExpiryItemsView, Object.assign({}, props, {resourceName: Resources.FleetExpiry}))}/>
                <PrivateRoute exact path="/fleet-settings"
                              component={(props) => getComponent(FleetSettingsView, props)}/>

                {/*Work Orders*/}
                <PrivateRoute exact path="/work-orders" component={(props) => getComponent(WorkOrdersView, props)}/>
                <PrivateRoute exact path="/work-orders-create"
                              component={(props) => getComponent(WorkOrdersCreate, props)}/>
                <PrivateRoute exact path="/work-orders/:id"
                              component={(props) => getComponent(WorkOrdersSingleView, props)}/>

                {/* Rentals routes */}
                <PrivateRoute exact path="/rentals-create"
                              component={(props) => getComponent(RentalsCreate, props)}/>
                <PrivateRoute exact path="/rentals"
                              component={(props) => getComponent(RentalsView, props)}/>
                <PrivateRoute exact path="/rentals/:id"
                              component={(props) => getComponent(RentalsSingleView, props)}/>
                <PrivateRoute exact path="/rentals/copy/:id"
                              component={(props) => getComponent(RentalsSingleView, props)}/>
                <PrivateRoute exact path="/rental-contracts"
                              component={(props) => getComponent(RentalContractsView, props)}/>
                <PrivateRoute exact path="/rental-contracts/:id"
                              component={(props) => getComponent(RentalContractsSingleView, props)}/>

                {/* Safety routes */}
                <PrivateRoute exact path="/accidents" component={(props) => getComponent(AccidentsView, props)}/>
                <PrivateRoute exact path="/accidents/create"
                              component={(props) => getComponent(AccidentsCreateView, props)}/>
                <PrivateRoute exact path="/accidents/info/:id"
                              component={(props) => getComponent(AccidentsCreateView, props)}/>

                {/* Personnel routes */}
                <PrivateRoute exact path="/employees-page"
                              component={(props) => getComponent(EmployeesPageView, props)}/>
                <PrivateRoute exact path="/employees/create"
                              component={(props) => getComponent(EmployeeSingleView, props)}/>
                <PrivateRoute exact path="/employees/info/:id"
                              component={(props) => getComponent(EmployeeSingleView, props)}/>

                <PrivateRoute exact path="/agents" component={(props) => getComponent(AgentsView, props)}/>
                <PrivateRoute exact path="/agent-create"
                              component={(props) => getComponent(AgentSingleView, props)}/>
                <PrivateRoute exact path="/agents/info/:id"
                              component={(props) => getComponent(AgentSingleView, props)}/>

                {/*General routes*/}
                <PrivateRoute exact path="/tools" component={(props) => getComponent(UiToolsView, props)}/>
                <PrivateRoute exact path="/integrations-center"
                              component={(props) => getComponent(IntegrationsTab, props)}/>
                <PrivateRoute exact path="/ticketing" component={(props) => getComponent(TicketsView, props)}/>
                <PrivateRoute exact path="/my-profile" component={(props) => getComponent(Profiles, props)}/>
                <PrivateRoute exact path="/account" component={(props) => getComponent(AccountView, props)}/>
                <PrivateRoute exact path="/logged-devices"
                              component={(props) => getComponent(LoggedDevicesView, props)}/>
                <NeutralRoute component={(props) => getComponent(NotFoundView, props)}/>
            </Switch>

            <Notifications
                history={history} dispatch={store.dispatch} {...store.getState()}
                translate={(key, args) => translator.translate(key, args)}
                generateNotificationBaseOnTitle={generateCustomNotification}
            />

            <TaskGrabData
                dispatch={store.dispatch}
            />
        </Router>
    );
}
