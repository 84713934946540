import {Field} from "../../../data/services/fields";
import React from "react";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Resources from "../../../data/services/resources";
import DocumentExpiryDate from "../../../common/components/badge/expiery-date-badge";
import DocumentsList from "../../../common/components/tabs/documents-list";
import {UPDATE_PERM} from "../../../common/util/util-consts";
import {checkPerm} from "../../../common/util/util-helpers";

export default function RentalContractsSingleDocumentsTab({history, match, id, uploadDocumentButtonRef, translate}) {


    /** Helpers
     ================================================================= */
    function getFields() {
        return Object.assign({}, {
            Description: new Field("Description", '', [], false, 'textarea'),
            DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select'),
            UpdatedByContact: new Field("UpdatedByContact", '', [''], false, 'custom', {
                render: (item) => <>{item.UpdatedByContact ?? translate("text.system")}</>,
                omitSort: true
            }),
            CreateUpdateDate: new Field("CreateUpdateDate", '', ['empty'], false, 'date'),
            ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {
                render: (item) => {
                    return <DocumentExpiryDate expiryDate={item?.ExpiryDate}/>
                }
            })
        })
    }

    function getUploadDocumentFields(item = {}) {
        const fieldTemplates = {
            DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: "col-span-full"}),
            ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: "col-span-full"}),
            Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: "col-span-full"}),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    function getQuery() {
        return {id: id}
    }

    return (
        // <DocumentsList
        //     uploadDocumentButtonRef={uploadDocumentButtonRef}
        //     getDocumentUploadFields={getUploadDocumentFields}
        //     getDocumentTableFields={getFields}
        //     id={id}
        //     fileUploadPiggyQuery={getQuery()}
        //     fileUploadPiggyResource={Resources.WorkOrdersRentalContractsDocuments}
        //     locationPath={Resources.WorkOrdersRentalContractsDocuments}
        //     fileUploadResource={Resources.WorkOrdersRentalContractsDocuments}
        //     accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
        //     titleNoDocuments="No rental contacts found in database"
        //     translate={translate}
        // />

        <DocumentsList
            id={id}
            primarySortKey={"DocumentName"}
            deleteDocumentKey={"DocumentID"}
            uploadDocumentButtonRef={uploadDocumentButtonRef}
            disableEdit={true}
            haveUpdatePermission={checkPerm(Resources.WorkOrdersRentalContractsDocuments, UPDATE_PERM)}
            documentsResource={Resources.WorkOrdersRentalContractsDocuments}
            downloadDocumentResource={Resources.WorkOrdersRentalContractsDocuments}
            locationPath={"work-orders-rental-documents-tab"}
            primaryDocumentKey={"DocumentID"}
            getDocumentUploadFields={(item) => {
                return {
                    "RentalContractID": new Field('RentalContractID', id, ['empty'], false, 'hidden'),
                    "Description": new Field('Description', item?.Description ?? "", ['empty'], false, 'text', {addContainerClass: "col-span-full"})
                }
            }}
            getDocumentTableFields={() => {
                return {
                    "DocumentName": new Field('DocumentName', '', [''], false),
                    "Description": new Field('Description', '', [''], false),
                    "CreateUpdateDate": new Field('CreateUpdateDate', '', [''], false, 'datetimezone'),
                    "UploadedBy": new Field('UploadedBy', '', [''], false, 'custom', {
                        render: (item) => {
                            return (<div>{item.FirstName} {item.LastName}</div>)
                        },
                        omitSort: true
                    })
                }
            }}
            onGetQueryFields={() => {
                return {
                    // "searchFields": new Field('searchFields', JSON.stringify({RentalID: ID}), [''], false, 'hidden'),
                    "id": new Field('id', id, [''], false, 'hidden'),
                }
            }}
            translate={translate}
        />

    )
}