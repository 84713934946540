import {checkPerm, getProp, returnSameKeyValueObject} from "../common/util/util-helpers";
import Resources from "../data/services/resources";
import {Field} from "../data/services/fields";
import LocalStorage from "../common/util/localStorage";

export const SECTIONS_VISIBILITY = {
    "hasQuickMenu": false,
    "hasLoadSection": false,
    "hasGlobalSearch": false,
    "hasCustomShortcuts": false,
    "hasCompaniesSection": false,
    "hasNotificationMenu": false,
    "hasEmployeesSection": false,
    "hasVendorsSubSection": false,
    "hasCarriersSubSection": false,
    "hasCustomersSubSection": false,
}

export const DEFAULT_DATABASE_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DEFAULT_FRONTEND_DATETIME_FORMAT = "MMMM Do YYYY, h:mm:ss a";
export const DEFAULT_DATABASE_TIMEZONE = "UTC";

export const DEFAULT_QUERY = {
    sort: "ASC",
    offset: 0,
    limit: 25,
}
export const DEFAULT_SORT = "ASC";
export const DEFAULT_OFFSET = "0";
export const DEFAULT_LIMIT = "20";

export const queryLimit = { // This is a duplicate named similarly as existing
    10: 10,
    20: 20,
    50: 50,
    100: 100,
    200: 200,
    300: 300
    // 400: 400,
    // 500: 500,
}


// Work order statuses
export const WO_REQUESTED = 1;
export const WO_HEADING_TO_SHOP = 2;
export const WO_TOWING_TO_SHOP = 3;
export const WO_AT_REPAIR_SHOP = 4;
export const WO_INSPECTING = 5;
export const WO_AWAITING_APPROVAL = 6;
export const WO_AWAITING_APPROVED = 7;
export const WO_AWAITING_WAITING_ON_PARTS = 9;
export const WO_AWAITING_PENDING_REPAIR = 10;
export const WO_REPAIRING = 11;
export const WO_REPAIR_COMPLETED = 12;
export const WO_PAID = 13;
export const WO_AWAITING_REJECTED = 8;
export const WO_REQUEST_REJECTED = 14;
export const WO_Return_FOR_Evaluation = 15;
export const WO_Return_for_Repairs = 16;
export const WO_Return_for_Maintenance = 17;

export const WORK_ORDER_STATUS_OPTIONS = {
    // Open
    [WO_REQUESTED]: "WO_REQUESTED",
    [WO_HEADING_TO_SHOP]: "WO_HEADING_TO_SHOP",
    [WO_TOWING_TO_SHOP]: "WO_TOWING_TO_SHOP",
    [WO_AT_REPAIR_SHOP]: "WO_AT_REPAIR_SHOP",
    [WO_INSPECTING]: "WO_INSPECTING",
    [WO_AWAITING_APPROVAL]: "WO_AWAITING_APPROVAL",
    [WO_AWAITING_APPROVED]: "WO_AWAITING_APPROVED",

    [WO_AWAITING_WAITING_ON_PARTS]: "WO_AWAITING_WAITING_ON_PARTS",
    [WO_AWAITING_PENDING_REPAIR]: "WO_AWAITING_PENDING_REPAIR",
    [WO_REPAIRING]: "WO_REPAIRING",
    [WO_REPAIR_COMPLETED]: "WO_REPAIR_COMPLETED",

    [WO_Return_FOR_Evaluation]: "WO_Return_FOR_Evaluation",
    [WO_Return_for_Repairs]: "WO_Return_for_Repairs",
    [WO_Return_for_Maintenance]: "WO_Return_for_Maintenance",
    // Closed
    [WO_PAID]: "WO_PAID",
    [WO_AWAITING_REJECTED]: "WO_AWAITING_REJECTED",
    [WO_REQUEST_REJECTED]: "WO_REQUEST_REJECTED",
}

export const RENTAL_STATUS_BOOKING_REQUESTED = 1;
export const RENTAL_STATUS_NEGOTIATION = 2;
export const RENTAL_STATUS_APPOVAL_PENDING = 3;
export const RENTAL_STATUS_DOWN_PAYMENT_REQUIRED = 4;
export const RENTAL_STATUS_APPROVED = 5;
export const RENTAL_STATUS_SCHEDULED_PICKUP = 6;
export const RENTAL_STATUS_EQUIPMENT_READY = 7;
export const RENTAL_STATUS_RENTAL_ACTIVE = 8;
export const RENTAL_STATUS_COMPLETED = 9;

export const RENTAL_STATUS = {
    [RENTAL_STATUS_BOOKING_REQUESTED]: "text.RENTAL_STATUS_BOOKING_REQUESTED",
    [RENTAL_STATUS_NEGOTIATION]: "text.RENTAL_STATUS_NEGOTIATION",
    [RENTAL_STATUS_APPOVAL_PENDING]: "text.RENTAL_STATUS_APPOVAL_PENDING",
    [RENTAL_STATUS_DOWN_PAYMENT_REQUIRED]: "text.RENTAL_STATUS_DOWN_PAYMENT_REQUIRED",
    [RENTAL_STATUS_APPROVED]: "text.RENTAL_STATUS_APPROVED",
    [RENTAL_STATUS_SCHEDULED_PICKUP]: "text.RENTAL_STATUS_SCHEDULED_PICKUP",
    [RENTAL_STATUS_EQUIPMENT_READY]: "text.RENTAL_STATUS_EQUIPMENT_READY",
    [RENTAL_STATUS_RENTAL_ACTIVE]: "text.RENTAL_STATUS_RENTAL_ACTIVE",
    [RENTAL_STATUS_COMPLETED]: "text.RENTAL_STATUS_COMPLETED",
}

export const COUNTRY_ID_USA = 1;
export const COUNTRY_ID_CANADA = 3;


export const SENSITIVE_LOAD_LIST_FIELDS = [
    'Price',
    'PriceBase',
    'AccessorialAmount',
    'DriverPayAmount',
    'CommisionAmount',
    'CarrierAmount',
    'AccessorialDriverPayAmount',
    'CalculationPrice'
];

export const FIXED_ASSET_TYPES = {
    1: 'Truck',
    2: 'Trailer',
    3: 'Office equipment',
    4: 'IT equipment',
    5: 'Land',
    6: 'Building',
    7: 'Vehicle',
    8: 'Machinery',
    9: 'Other'
};

export const IFTA_PER_DRIVER_CALC_TYPE = {
    1: "Based on total driven mileage",
    2: "Based on driven mileage per state",
    3: "Based on driven mileage and fuel purchased per state"
}

export const LINE_OF_BUSINESS_PRIMARY_CARRIER = "2";
export const LINE_OF_BUSINESS_PRIMARY_DRIVER = "1";

// Companies
export const BANK_ACCOUNT_TYPES = {
    1: "Business Savings",
    2: "Business Checking",
    3: "Personal Checking",
    4: "Personal Savings",
}

// Matrix pricing types
export const MATRIX_PRICING_ZIP = 1;
export const MATRIX_PRICING_ZONE = 2;

// Label Colors
export const TAG_LABEL_COLORS = ['#171219', '#225560', '#E9DF00', '#F0803C', '#FF66B3', '#084B83', '#42BFDD', '#72BDA3', '#03FCBA', '#770058']

// Load pricing types
export const LOAD_PRICING_FLAT = 1;
export const LOAD_PRICING_PER_MILE = 2;
export const LOAD_PRICING_PER_WEIGHT = 3;
export const LOAD_PRICING_PER_VOLUME = 4;
export const LOAD_PRICING_PER_PIECE = 5;

export const LOAD_PRICING_TYPES = {
    [LOAD_PRICING_FLAT]: "flat",
    [LOAD_PRICING_PER_MILE]: "per_mile",
    [LOAD_PRICING_PER_WEIGHT]: "per_weight",
    [LOAD_PRICING_PER_VOLUME]: "per_volume",
    [LOAD_PRICING_PER_PIECE]: "per_piece",
}

// Truck/Trailer Status
export const TRUCK_STATUS_AVAILABLE = 1
export const TRUCK_STATUS_BOOKED = 2
export const TRUCK_STATUS_ON_LOAD = 3
export const TRUCK_STATUS_OUT_OF_SERVICE = 4
export const TRUCK_STATUS_SOLD = 5
export const TRUCK_STATUS_RETIRED = 6

export const TRUCK_STATUS_TYPES = {
    [TRUCK_STATUS_AVAILABLE]: "Available",
    [TRUCK_STATUS_BOOKED]: "Booked",
    [TRUCK_STATUS_ON_LOAD]: "On Load",
    [TRUCK_STATUS_OUT_OF_SERVICE]: "Out of service",
    [TRUCK_STATUS_SOLD]: "Sold",
    [TRUCK_STATUS_RETIRED]: "Retired"
}

export const TRUCK_STATUS_TYPE_TIPPY_TEXT = {
    [TRUCK_STATUS_AVAILABLE]: "TRUCK_STATUS_AVAILABLE",
    [TRUCK_STATUS_BOOKED]: "TRUCK_STATUS_BOOKED",
    [TRUCK_STATUS_ON_LOAD]: "TRUCK_STATUS_ON_LOAD",
    [TRUCK_STATUS_OUT_OF_SERVICE]: "TRUCK_STATUS_OUT_OF_SERVICE",
    [TRUCK_STATUS_SOLD]: "TRUCK_STATUS_SOLD",
    [TRUCK_STATUS_RETIRED]: "TRUCK_STATUS_RETIRED"
}

export const TRAILER_STATUS_AVAILABLE = 1
export const TRAILER_STATUS_BOOKED = 2
export const TRAILER_STATUS_ON_LOAD = 3
export const TRAILER_STATUS_OUT_OF_SERVICE = 4
export const TRAILER_STATUS_SOLD = 5
export const TRAILER_STATUS_RETIRED = 6

export const TRAILER_STATUS_TYPE_TIPPY_TEXT = {
    [TRAILER_STATUS_AVAILABLE]: "TRAILER_STATUS_AVAILABLE",
    [TRAILER_STATUS_BOOKED]: "TRAILER_STATUS_BOOKED",
    [TRAILER_STATUS_ON_LOAD]: "TRAILER_STATUS_ON_LOAD",
    [TRAILER_STATUS_OUT_OF_SERVICE]: "TRAILER_STATUS_OUT_OF_SERVICE",
    [TRAILER_STATUS_SOLD]: "TRAILER_STATUS_SOLD",
    [TRAILER_STATUS_RETIRED]: "TRAILER_STATUS_RETIRED"
}


// Driver Status
export const DRIVER_STATUS_AVAILABLE = 1
export const DRIVER_STATUS_BOOKED = 2
export const DRIVER_STATUS_ON_LOAD = 3
export const DRIVER_STATUS_ON_VACATION = 4
export const DRIVER_STATUS_INACTIVE = 5
export const DRIVER_STATUS_SUSPENDED = 6
export const DRIVER_STATUS_PENDING = 7
export const DRIVER_STATUS_TERMINATED = 8

export const DRIVER_STATUS_TYPES = {
    [DRIVER_STATUS_AVAILABLE]: "Available",
    [DRIVER_STATUS_BOOKED]: "Booked",
    [DRIVER_STATUS_ON_LOAD]: "On Load",
    [DRIVER_STATUS_ON_VACATION]: "On Time off",
    [DRIVER_STATUS_INACTIVE]: "Inactive",
    [DRIVER_STATUS_SUSPENDED]: "Suspended",
    [DRIVER_STATUS_PENDING]: "Pending",
    [DRIVER_STATUS_TERMINATED]: "Terminated"
}

export const DRIVER_STATUS_TYPE_TIPPY_TEXT = {
    [DRIVER_STATUS_AVAILABLE]: "DRIVER_STATUS_AVAILABLE",
    [DRIVER_STATUS_BOOKED]: "DRIVER_STATUS_BOOKED",
    [DRIVER_STATUS_ON_LOAD]: "DRIVER_STATUS_ON_LOAD",
    [DRIVER_STATUS_ON_VACATION]: "DRIVER_STATUS_ON_VACATION",
    [DRIVER_STATUS_INACTIVE]: "DRIVER_STATUS_INACTIVE",
    [DRIVER_STATUS_SUSPENDED]: "DRIVER_STATUS_SUSPENDED",
    [DRIVER_STATUS_PENDING]: "DRIVER_STATUS_PENDING",
    [DRIVER_STATUS_TERMINATED]: "DRIVER_STATUS_TERMINATED"
}

export const EMPLOYEE_STATUS_ACTIVE = 1
export const EMPLOYEE_STATUS_PAUSED = 2
export const EMPLOYEE_STATUS_INACTIVE = 3
export const EMPLOYEE_STATUS_PENDING = 7

export const EMPLOYEE_STATUS_TYPES = {
    [EMPLOYEE_STATUS_ACTIVE]: "Active",
    [EMPLOYEE_STATUS_PAUSED]: "Time off",
    [EMPLOYEE_STATUS_INACTIVE]: "Inactive",
    [EMPLOYEE_STATUS_PENDING]: "Pending"
}

export const EMPLOYEE_STATUS_TYPE_TIPPY_TEXT = {
    [EMPLOYEE_STATUS_ACTIVE]: "EMPLOYEE_STATUS_ACTIVE",
    [EMPLOYEE_STATUS_PAUSED]: "EMPLOYEE_STATUS_PAUSED",
    [EMPLOYEE_STATUS_INACTIVE]: "EMPLOYEE_STATUS_INACTIVE",
    [EMPLOYEE_STATUS_PENDING]: "EMPLOYEE_STATUS_PENDING"
}

export const INDIVIDUAL_SOLE_PROPRIETOR = 2;

// Payment methods
export const PAYMENT_METHOD_CACHE = 1;
export const PAYMENT_METHOD_CHECK = 2;
export const PAYMENT_METHOD_CARD = 3;
export const PAYMENT_METHOD_DEBIT = 4;
export const PAYMENT_METHOD_FACTOR = 5;
export const PAYMENT_METHOD_PAYMENT_PROCESSOR = 6;
export const PAY_METHOD_TYPE_SALARY_MONTHLY = 1
export const PAY_METHOD_TYPE_SALARY_WEEKLY = 2
export const PAY_METHOD_TYPE_HOURLY = 3

export const PAY_METHOD_TYPES = {
    [PAY_METHOD_TYPE_SALARY_MONTHLY]: "PAY_METHOD_TYPE_SALARY_MONTHLY",
    [PAY_METHOD_TYPE_SALARY_WEEKLY]: "PAY_METHOD_TYPE_SALARY_WEEKLY",
    [PAY_METHOD_TYPE_HOURLY]: "PAY_METHOD_TYPE_HOURLY",
}

// Invoice statutses
export const INVOICE_STATUS_OPEN = 1
export const INVOICE_STATUS_SENT = 2
export const INVOICE_STATUS_PAID = 3

// Account Types
export const MAJOR_ACCOUNT_TYPE_EXPENSE = 1;
export const MAJOR_ACCOUNT_TYPE_INCOME = 2;
export const MAJOR_ACCOUNT_TYPE_ASSET = 3;
export const MAJOR_ACCOUNT_TYPE_LIABILITY = 4;

// DEFAULT CHART OF ACCOUNTS
// const ACCOUNT_TYPE_INCOME;
export const ACCOUNT_TYPE_INCOME = 1; // Income
export const ACCOUNT_OTHER_INCOME = 2; // Other Income

// const ACCOUNT_TYPE_EXPENSE;
export const ACCOUNT_TYPE_COST_OF_SALES = 3; // Cost of sales
export const ACCOUNT_OPERATING_EXPENSES = 4; // Expenses (Operating expenses)
export const ACCOUNT_TYPE_EXPENSE = 5; // Other expenses

// const ACCOUNT_TYPE_ASSET;
export const ACCOUNT_TYPE_CURRENT_ASSETS = 0; // Current assets (Will server as grouping anchor only for next three)
export const ACCOUNT_TYPE_CASH = 6; // Cash and cash equivalents
export const ACCOUNT_RECEIVABLE = 7; // Accounts Receivable (A/R)
export const ACCOUNT_TYPE_OTHER_CURRENT_ASSETS = 8; // Other current assets
export const ACCOUNT_TYPE_FIXED_ASSETS = 9; // Fixed assets
export const ACCOUNT_TYPE_OTHER_NON_CURRENT_ASSETS = 10; // Other non-current assets

// const ACCOUNT_TYPE_LIABILITY;
export const ACCOUNT_TYPE_CURRENT_LIABILITY = 0; // Current liabilities (Will server as grouping anchor only for next three)
export const ACCOUNT_CREDIT_CARD = 11; // Credit card
export const ACCOUNT_PAYABLE = 12; // Accounts Payable (A/P)
export const ACCOUNT_TYPE_OTHER_CURRENT_LIABILITY = 13; // Other current liabilities
export const ACCOUNT_TYPE_NON_CURRENT_LIABILITY = 14; // Non current liabilities
export const ACCOUNT_TYPE_OTHER_NON_CURRENT_LIABILITY = 15; // Other non-current liabilities

// Equity
export const ACCOUNT_TYPE_OWNER_EQUITY = 16; // Owner's equity

// DOCUMENTS
export const REACT_DROPZONE_ONE_MEGABYTE = 1048576  // in bytes
export const DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS = "image/jpg,image/jpeg, image/png, .xlsx, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, application/pdf, .ods, .odt, .ott, .ots, .rtf, .txt, .csv"
export const DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS = "image/jpg,image/jpeg, image/png"
export const MAXIMUM_DOCUMENT_UPLOAD_SIZE = 25 * REACT_DROPZONE_ONE_MEGABYTE

// PERMISSIONS
export const READ_PERM = 1;
export const CREATE_PERM = 2;
export const UPDATE_PERM = 4;
export const DELETE_PERM = 8;

// Load Types
export const TL = 1;
export const LTL = 2;
export const TLG = 3;

export const LOAD_SHEET = 1;
export const BILL_OF_LADING = 2;
export const CUSTOMER_AGREEMENT = 3;
export const DISPATCH_CONFIRMATION = 4;
export const ACCESSORIAL_NOTIFICATION = 5

export const LOAD_MANIFEST_TYPES = {
    [LOAD_SHEET]: "text.load_sheet",
    [BILL_OF_LADING]: "text.bill_of_lading",
    [CUSTOMER_AGREEMENT]: "text.customer_agreement",
    [DISPATCH_CONFIRMATION]: "text.dispatch_confirmation",
    [ACCESSORIAL_NOTIFICATION]: "text.accessorial_notification"
}

// Load Status
export const LOAD_STATUS_NEW = 0;
export const LOAD_STATUS_CANCELED = 1;
export const LOAD_STATUS_POSSIBLE = 2;
export const LOAD_STATUS_READY = 3;
export const LOAD_STATUS_RESERVED = 4;
export const LOAD_STATUS_DISPATCHED = 5;
export const LOAD_STATUS_IN_TRANSIT = 6;
export const LOAD_STATUS_DELIVERED = 7;

export const LOAD_STATUSES = {
    [LOAD_STATUS_NEW]: "text.LOAD_STATUS_NEW",
    [LOAD_STATUS_CANCELED]: "text.LOAD_STATUS_CANCELED",
    [LOAD_STATUS_POSSIBLE]: "text.LOAD_STATUS_POSSIBLE",
    [LOAD_STATUS_READY]: "text.LOAD_STATUS_READY",
    [LOAD_STATUS_RESERVED]: "text.LOAD_STATUS_RESERVED",
    [LOAD_STATUS_DISPATCHED]: "text.LOAD_STATUS_DISPATCHED",
    [LOAD_STATUS_IN_TRANSIT]: "text.LOAD_STATUS_IN_TRANSIT",
    [LOAD_STATUS_DELIVERED]: "text.LOAD_STATUS_DELIVERED",
}

// export const NUMBER_OF_AXLES = returnSameKeyValueObject(2, 12)

// Location Stop Types
export const STOP_TYPE_STOP_BY = 0;
export const STOP_TYPE_PICKUP = 1;
export const STOP_TYPE_PICKUP_AND_DELIVERY = 4;
export const STOP_TYPE_DELIVERY = 5;

export const STOP_TYPES = {
    [STOP_TYPE_PICKUP]: 'Pickup', // old "Shipper",
    // 2: "Pickup",
    // 3: "Delivery",
    [STOP_TYPE_PICKUP_AND_DELIVERY]: 'Delivery & Pickup',
    [STOP_TYPE_DELIVERY]: 'Delivery' // old "Cosignee"
}

// Specific field code length
export const SCAC_CODE_FIELD_LENGTH = 4
export const VIN_CODE_FIELD_LENGTH = 17

export const NUMBER_OF_AXLES = returnSameKeyValueObject(2, 12)

// Measure Unit
export const MEASURE_UNIT_INCHES = 1;
export const MEASURE_UNIT_FEET = 2;
export const MEASURE_UNIT_CM = 4;
export const MEASURE_UNIT_METER = 5;

export const MEASURE_UNITS = {
    [MEASURE_UNIT_INCHES]: "MEASURE_UNIT_INCHES",
    [MEASURE_UNIT_FEET]: "MEASURE_UNIT_FEET",
    [MEASURE_UNIT_CM]: "MEASURE_UNIT_CM",
    [MEASURE_UNIT_METER]: "MEASURE_UNIT_METER"
}

export const EVENT_STATUS_UPDATE = 1
export const EVENT_SUB_STATUS_UPDATE = 2
export const DISPATCHER_NOTE = 4
export const CARRIER_NOTE = 5
export const EVENT_INFO_UPDATE = 6

export const EVENT_STOP_UPDATE = 7
export const EVENT_STOP_COMPLETE = 8

export const EVENT_DRIVER_NOTE = 9

export const EVENT_TYPES = {
    [EVENT_STATUS_UPDATE]: "EVENT_STATUS_UPDATE",
    [EVENT_SUB_STATUS_UPDATE]: "EVENT_SUB_STATUS_UPDATE",
    [DISPATCHER_NOTE]: "DISPATCHER_NOTE",
    [CARRIER_NOTE]: "CARRIER_NOTE",
    [EVENT_INFO_UPDATE]: "EVENT_INFO_UPDATE",
    [EVENT_STOP_UPDATE]: "EVENT_STOP_UPDATE",
    [EVENT_STOP_COMPLETE]: "EVENT_STOP_COMPLETE",
    [EVENT_DRIVER_NOTE]: "EVENT_DRIVER_NOTE",
}

// Weight unit
export const WEIGHT_UNIT_LBS = 1;
export const WEIGHT_UNIT_KG = 2;

// Task Status
export const TASK_CHANGE_STATUS = 1;
export const TASK_CHANGE_ASSIGNEE = 2;
export const TASK_CHANGE_CREATE = 3;
export const TASK_CHANGE_START_DATE = 4;
export const TASK_CHANGE_DUE_DATE = 5;

export const TASK_STATUS_OPEN = 1
export const TASK_STATUS_IN_PROGRESS = 2
export const TASK_STATUS_COMPLETED = 3
export const TASK_STATUS_CLOSED = 4

export const FIELD_MASK_PHONE = [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];
export const FIELD_MASK_SOCIAL_SECURITY = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

export const DISTANCE_UNIT_KILOMETERS_ID = 2
export const DISTANCE_UNIT_MILES_ID = 1

export const FUEL_SURCHARGE_MATRIX_TYPES = {
    1: "Table"
}

// STATE
export const DEFAULT_CRUD_STATE = {
    offset: 0,
    limit: 10,
    sort: "ASC",
    sortBy: "",
    paginationPage: 1,
    archived: false
};

export const DEFAULT_METADATA_SELECT_SEARCH_QUERY = () => {
    return {
        limit: 10,
        offset: 0
    }
}

export const DEFAULT_METADATA_SELECT_LOCATION_LOAD_SEARCH_QUERY = () => {
    return {
        limit: 50,
        offset: 0
    }
}

export const WITH_DEFAULT_METADATA_SELECT_SEARCH_QUERY = (additionalQuery = {}) => {
    return Object.assign(DEFAULT_METADATA_SELECT_SEARCH_QUERY(), additionalQuery);
}

export const Months = {
    1: {
        month: "January",
        days: 31
    },
    2: {
        month: "February",
        days: 28
    },
    3: {
        month: "March",
        days: 31
    },
    4: {
        month: "April",
        days: 30
    },
    5: {
        month: "May",
        days: 31
    },
    6: {
        month: "June",
        days: 30
    },
    7: {
        month: "July",
        days: 31
    },
    8: {
        month: "August",
        days: 31
    },
    9: {
        month: "September",
        days: 30
    },
    10: {
        month: "October",
        days: 31
    },
    11: {
        month: "November",
        days: 30
    },
    12: {
        month: "December",
        days: 31
    }
}

export const MonthsSimple = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
}

export const keyboardShortcuts = {
    "NewLoad": {
        perm: checkPerm(Resources.Loads, UPDATE_PERM),
        shortcut: "Shift+1"
    },
    "NewDriver": {
        perm: checkPerm(Resources.Drivers, CREATE_PERM),
        shortcut: "Shift+2"
    },
    "NewTrailer": {
        perm: checkPerm(Resources.Trailers, CREATE_PERM),
        shortcut: "Shift+3"
    },
    "NewTruck": {
        perm: checkPerm(Resources.Trucks, CREATE_PERM),
        shortcut: "Shift+4"
    },
    "NewLocation": {
        perm: checkPerm(Resources.Locations, CREATE_PERM),
        shortcut: "Shift+5"
    },
    "GrabTask": {
        perm: true,
        shortcut: "Shift+6"
    },
    "CreateTask": {
        perm: checkPerm(Resources.Tasks, CREATE_PERM),
        shortcut: "Shift+7"
    },
    "MyTasks": {
        perm: true,
        shortcut: "Shift+8"
    },
    "OpenSearch": {
        perm: true,
        shortcut: "Shift+W"
    },
    "OpenChat": {
        perm: true,
        shortcut: "Shift+E"
    },
    "DarkMode": {
        perm: true,
        shortcut: "Shift+R"
    },
    "NewNote": {
        perm: true,
        shortcut: "Shift+N"
    }
}

export const DIALOG_HIDE_MS = 500;
export const TABLE_SETTINGS_SUF = "_table_settings";
export const TABLE_OPTIONS_SUF = "_table_options";

// BookDateDefault
export const BookDateDefaultInvoice = 1;
export const BookDateDefaultPickup = 2;
export const BookDateDefaultDelivery = 3;

// Default resource fields
export const DEFAULT_CONTACT_INFO_FIELDS = () => {
    const colSpan2 = "md:col-span-6";
    const colSpan4 = "  md:col-span-full";

    return {
        Email: new Field('Email', '', ['empty', 'email'], false, 'text', {
            addContainerClass: colSpan4,
            label: "BusinessEmail"
        }),
        PersonalTitleID: new Field('PersonalTitleID', '', [''], false, 'select', {addContainerClass: "md:col-span-4"}),
        FirstName: new Field('FirstName', '', ['empty'], false, 'text', {addContainerClass: "md:col-span-4 md:col-start-1"}),
        MiddleName: new Field('MiddleName', '', [], false, "text", {addContainerClass: "md:col-span-4"}),
        LastName: new Field('LastName', '', ['empty'], false, 'text', {addContainerClass: "md:col-span-4"}),
        Nickname: new Field('Nickname', '', [], false, "text", {addContainerClass: "md:col-span-4 md:col-start-1"}),
        Title: new Field('Title', '', [''], false, "text", {addContainerClass: "md:col-span-4"}),
        DateOfBirth: new Field('DateOfBirth', '', [], false, "date", {addContainerClass: "md:col-span-4"}),

        PrivateEmail: new Field('PrivateEmail', '', [], false, "email", {addContainerClass: colSpan2}),

        // HighImportance: new Field('HighImportance', 0, [], false, "checkbox", {addContainerClass: colSpan4}),
        // Notes: new Field('Notes', '', [], false, "textarea", {addContainerClass: colSpan4}),
        IsSystemUser: new Field('IsSystemUser', 0, [], false, "checkbox", {addContainerClass: colSpan4}),
        Roles: new Field('Roles', '', ['empty'], false, "multi-select", {addContainerClass: colSpan4}),
        Groups: new Field('Groups', '', [''], false, "multi-select-search", {
            addContainerClass: colSpan4,
            label: 'ContactDepartments'
        }, {
            multi: true
        }),
        ValidationType: new Field('ValidationType', '', ['empty'], false, "select", {addContainerClass: colSpan4}),
    }
}

export const DEFAULT_PHONE_TYPE_ID = getProp(LocalStorage.get("lookup"), 'PhoneType', [{PhoneTypeID: ''}])[0]?.PhoneTypeID

export const DEFAULT_PHONES_FIELDS = () => {
    return {
        PhoneTypeID: new Field('PhoneTypeID', DEFAULT_PHONE_TYPE_ID, ['empty'], false, 'select', {addContainerClass: "js-phone-type-focus"}),
        AreaCode: new Field('AreaCode', LocalStorage.get("user")?.Office?.CountryCode ?? '', [''], false, 'select'),
        PhoneNumber: new Field('PhoneNumber', '', [''], false, 'mask', {}, {
            showMask: true,
            mask: FIELD_MASK_PHONE
        }),
        PhoneExtension: new Field('PhoneExtension', '', []),
        IsPrimary: new Field('IsPrimary', 0, [], true, 'hidden')
    }
}
export const JOURNAL_ENTRY_TYPE_JOURNAL = 1
export const JOURNAL_ENTRY_TYPE_INVOICE = 2
export const JOURNAL_ENTRY_TYPE_PAYMENT = 3
export const JOURNAL_ENTRY_TYPE_RECEIPT = 4
export const JOURNAL_ENTRY_TYPE_BILL = 5
export const JOURNAL_ENTRY_TYPE_EXPENSE = 6
export const JOURNAL_ADJUSTMENT_TYPE = 7
export const JOURNAL_RECV_PAYMENT_TYPE = 8

export const JOURNAL_DEPOSIT_TYPE = 9
export const JOURNAL_TRANSFER_TYPE = 10

export const JournalEntryTypeID = {
    [JOURNAL_ENTRY_TYPE_JOURNAL]: "Journal",
    [JOURNAL_ENTRY_TYPE_INVOICE]: "Invoice",
    [JOURNAL_ENTRY_TYPE_PAYMENT]: "Sent payment",
    [JOURNAL_ENTRY_TYPE_RECEIPT]: "Receipt",
    [JOURNAL_ENTRY_TYPE_BILL]: "Bill",
    [JOURNAL_ENTRY_TYPE_EXPENSE]: "Expense",
    [JOURNAL_ADJUSTMENT_TYPE]: "Adjustment",
    [JOURNAL_RECV_PAYMENT_TYPE]: "Received payment",
    [JOURNAL_DEPOSIT_TYPE]: "Deposit",
    [JOURNAL_TRANSFER_TYPE]: "Transfer"
}
export const TicketTypes = {
    1: "Bug",
    2: "Suggestion",
}
export const BugLevel = {
    1: "Low",
    2: "Medium",
    3: "High",
    4: "Highest",
}
export const loadStopTypes = {
    1: "Pickup",
    2: "Stop (pickup)",
    3: "Stop (delivery)",
    4: "Delivery & Pickup",
    5: "Delivery"
}
export const ApprovalStatusID = {
    1: "Pending",
    2: "Accepted",
    3: "Rejected",
}
export const Active = {
    "Active": "Active",
    "Paused": "Paused",
    "Inactive": "Inactive",
}
export const ticketingStatus = {
    1: "Pending",
    2: "Open",
    3: "In Progress",
    4: "Finished",
    5: "Closed",
}

export const LOAD_PAY_METHOD_FLAT = 1
export const LOAD_PAY_METHOD_PRICE_PERCENT = 2
export const LOAD_PAY_METHOD_ACC_PERCENT = 3
export const LOAD_PAY_METHOD_PRICE_ACC_PERCENT = 4
export const LOAD_PAY_METHOD_NET_PROFIT_PERCENT = 5
export const LOAD_PAY_METHOD_PRICE_LOADED_MILES = 6
export const LOAD_PAY_METHOD_PRICE_LOADED_AND_EMPTY_MILES = 7

export const feeTypes = {
    [LOAD_PAY_METHOD_FLAT]: "LOAD_PAY_METHOD_FLAT",
    [LOAD_PAY_METHOD_PRICE_PERCENT]: "LOAD_PAY_METHOD_PRICE_PERCENT",
    [LOAD_PAY_METHOD_ACC_PERCENT]: "LOAD_PAY_METHOD_ACC_PERCENT",
    [LOAD_PAY_METHOD_PRICE_ACC_PERCENT]: "LOAD_PAY_METHOD_PRICE_ACC_PERCENT",
    [LOAD_PAY_METHOD_NET_PROFIT_PERCENT]: "LOAD_PAY_METHOD_NET_PROFIT_PERCENT",
    [LOAD_PAY_METHOD_PRICE_LOADED_MILES]: "LOAD_PAY_METHOD_PRICE_LOADED_MILES",
    [LOAD_PAY_METHOD_PRICE_LOADED_AND_EMPTY_MILES]: "LOAD_PAY_METHOD_PRICE_LOADED_AND_EMPTY_MILES"
}

export const STOP_TYPE_BOTH = 1
export const STOP_TYPE_SHIPPER = 2
export const STOP_TYPE_CONSIGNEE = 3

export const LOCATION_STOP_TYPES = {
    [STOP_TYPE_BOTH]: "STOP_TYPE_BOTH",
    [STOP_TYPE_SHIPPER]: "STOP_TYPE_SHIPPER",
    [STOP_TYPE_CONSIGNEE]: "STOP_TYPE_CONSIGNEE",
}

export const LOCATION_TYPE_COMMERCIAL = 1
export const LOCATION_TYPE_RESIDENTIAL = 2

export const LOCATION_TYPES = {
    [LOCATION_TYPE_COMMERCIAL]: "LOCATION_TYPE_COMMERCIAL",
    [LOCATION_TYPE_RESIDENTIAL]: "LOCATION_TYPE_RESIDENTIAL",
}

export const layers = [
    {
        name: "Default",
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: "&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
    }, {
        name: "Dark mode",
        url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png",
        attribution: "&copy; <a href=\"http://www.openstreetmap.org/copyright\">OpenStreetMap</a> &copy; <a href=\"http://cartodb.com/attributions\">CartoDB</a>"
    },
    {
        name: "Satellite",
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        attribution: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
    },
    {
        name: "Google-Map",
        url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
        attribution: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
    },
    {
        name: "Google-Hybrid",
        url: "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
        attribution: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
    }
]

export const STOP_BY_TYPE_FUEL_STATION = 1;
export const STOP_BY_TYPE_REPAIR_SHOP = 2;
export const STOP_BY_TYPE_REST = 3;
export const STOP_BY_TYPE_HOME = 4;
export const STOP_BY_TYPE_WEIGHT_STATION = 5;
export const STOP_BY_TYPE_PARKING = 6;
export const STOP_BY_TYPE_SWITCH_ASSET = 7;
export const STOP_BY_TYPE_PERSONAL = 8;

export const STOP_BY_TYPES = {
    [STOP_BY_TYPE_FUEL_STATION]: "fuel_station",
    [STOP_BY_TYPE_REPAIR_SHOP]: "repair_shop",
    [STOP_BY_TYPE_REST]: "rest",
    [STOP_BY_TYPE_HOME]: "home",
    [STOP_BY_TYPE_WEIGHT_STATION]: "weight_station",
    [STOP_BY_TYPE_PARKING]: "parking",
    [STOP_BY_TYPE_SWITCH_ASSET]: "switch_asset",
    [STOP_BY_TYPE_PERSONAL]: "personal",
}

export const MINIMUM_STOP_NUMBER = 2;

export const PO_LINE_TYPE_EXPENSE = 1
export const PO_LINE_TYPE_PARTS = 2
export const PO_LINE_TYPE_ASSET = 3
export const PURCHASE_ORDER_LINE_TYPES = {
    [PO_LINE_TYPE_EXPENSE]: 'Expense',
    [PO_LINE_TYPE_PARTS]: 'Parts',
    [PO_LINE_TYPE_ASSET]: 'Asset'
}

export const WORKING_HOURS_NON_STOP = 1;
export const WORKING_HOURS_CUSTOM_HOURS = 2;

export const COLOR_PICKER_COLOR = ['#292524', '#4D7C0F', '#CA8A04', '#EA580C', '#DB2777', '#084B83', '#0284C7', '#059669', '#0891B2', '#770058'];

export const QUERY_LIMIT = {
    5: 5,
    10: 10,
    15: 15,
    20: 20,
    30: 30,
    50: 50,
    100: 100,
    200: 200,
    300: 300
}

export const DEFAULT_QUERY_LIMIT = 50

// Reconciled filter fields
export const RECONCILED_FILTER = {
    0: "All",
    1: "Reconciled",
    2: "Not reconciled"
}
// HISTORY TABLE CONSTANTS
export const DEFAULT_HISTORY_TABLE_FILTER_FIELDS = {
    query: new Field('query', '', [], false, 'search', {
        labelType: "float"
    }),
    StartDate: new Field('StartDate', '', [], false, 'date', {
        labelType: "float"
    }, {isClearable: true}),
    EndDate: new Field('EndDate', '', [], false, 'date', {
        labelType: "float"
    }, {isClearable: true}),
    limit: new Field('limit', '10', [''], false, 'select', {hideLabel: true})
}

export const DEFAULT_EXCLUDED_LOOKUPS = ['DocumentType', 'RequestedServiceCategory', 'PreferredBillingMethod', 'HazmatClass', 'MeasureTemperature', 'TruckPermit', 'TicketType', 'TicketLevel', 'CompanyType', 'FeatureFlags', 'LoadTag', 'WeightUnit', 'TrackingMethod', 'DistanceUnit', 'MeasureUnit', 'LoadManifestType', 'LoadPayMethod', 'LoadWatcherType', 'LoadType', 'TaskTagType', 'ExpenseType', 'ExpenseStatus', 'BusinessType', 'State', 'BillType', 'EmploymentType', 'PaymentType', 'AccountType', 'Department', 'VacationStatus', 'BugLevel', 'TicketStatus', 'Country', 'PermRoles', 'LoadStatus', 'TaskStatus', 'PersonalTitle', 'NetTermType', 'BillAuditing', 'NotificationChannel', 'NotificationLevel', 'Day', 'EmploymentStatus', 'ContactValidationType', 'InvoiceSumType', 'TaskPriority', 'InvoiceStatus', 'CustomCode', 'OrganizationType', 'WorkOrderStatus']
export const DISPATCH_SETTINGS_INCLUDED_LOOKUPS = ['LoadSubType', 'LoadStopStatus', 'FreightClass', 'PackingGroup', 'UnitType']
export const ACCOUNTING_SETTINGS_INCLUDED_LOOKUPS = [];
export const COMPANIES_SETTINGS_INCLUDED_LOOKUPS = ['OrganizationEntityType', 'ReviewType', 'PhoneType', 'CarrierCertsType', 'Naics']
export const FLEET_SETTINGS_INCLUDED_LOOKUPS = ["TruckType", "TruckOwnershipType", "TruckCanGo", "TrailerType", "TrailerOwnershipType", "TransponderType", "AssetCompanyStatus"]
export const SAFETY_SETTINGS_INCLUDED_LOOKUPS = ['AccidentCode']
export const PERSONNEL_SETTINGS_INCLUDED_LOOKUPS = ["DriverEndorsementType", "DriverPositionType", "DriverRestrictionType", "LegalStatus", "PreferenceType", "Vacation", "DriverNotesType", "DriverAssignmentStatus"]
export const PLACES_SETTINGS_INCLUDED_LOOKUPS = [];

export const SETTINGS_LOOKUP_GROUPING = {
    'Dispatch': DISPATCH_SETTINGS_INCLUDED_LOOKUPS,
    'Accounting': ACCOUNTING_SETTINGS_INCLUDED_LOOKUPS,
    'Companies': COMPANIES_SETTINGS_INCLUDED_LOOKUPS,
    'Fleet': FLEET_SETTINGS_INCLUDED_LOOKUPS,
    'Safety': SAFETY_SETTINGS_INCLUDED_LOOKUPS,
    'Personnel': PERSONNEL_SETTINGS_INCLUDED_LOOKUPS,
    'Places': PLACES_SETTINGS_INCLUDED_LOOKUPS
};

export const REQUIRED_DOCUMENTS_TYPES = {
    'Dispatch': ['Load'],
    'Company': ['Carrier', 'Vendor', 'Customer'],
    'Fleet': ['Truck', 'Trailer'],
    'Safety': ['Accident', 'Claim'],
    'Personnel': ['Driver', 'Agent', 'Employee']
};

export const ALL_REQUIRED_TYPES = [
    'Load', 'Carrier', 'Vendor', 'Customer', 'Truck', 'Trailer', 'Accident', 'Claim', 'Driver', 'Agent', 'Employee'
]
// OFFICE CUSTOM SELECTS
export const DISPATCH_DOCUMENT_NAME = {1: 'Print Company Name', 2: 'Print Division Name'}
export const DISPATCH_DOCUMENT_ADDRESS = {
    1: 'Print Company Address',
    2: 'Print Division Address',
    3: 'Print Office Address'
}
export const DISPATCH_DOCUMENT_LOGO = {1: 'Print Company Logo', 2: 'Print Division Logo'}
export const ACCOUNTING_DOCUMENT_NAME = {1: 'Print Company Name', 2: 'Print Division Name'}
export const ACCOUNTING_DOCUMENT_ADDRESS = {1: 'Print Company Address', 2: 'Print Division Address'}
export const ACCOUNTING_DOCUMENT_LOGO = {1: 'Print Company Logo', 2: 'Print Division Logo'}

// TASK GROUPS
export const TASK_GROUP_MANAGER_ID = 1
export const TASK_GROUP_MEMBER_ID = 2

// Reference Types
export const REFERENCE_TYPE_TRUCK = 'tbl_Truck'
export const REFERENCE_TYPE_TRAILER = 'tbl_Trailer'
export const REFERENCE_TYPE_LOAD = 'tbl_Load'
export const REFERENCE_TYPE_DRIVER = 'tbl_Driver'
export const REFERENCE_TYPE_CARRIER = 'tbl_Carrier'
export const REFERENCE_TYPE_CUSTOMER = 'tbl_Customer'
export const REFERENCE_TYPE_VENDOR = 'tbl_Vendor'
export const REFERENCE_TYPE_FIXED_ASSET = 'tbl_FixedAsset'
export const REFERENCE_TYPE_MILEAGE_TAX_ENTRY = 'tbl_MileageTaxEntry'

export const REFERENCE_TYPES_SYSTEM_LINKS = {
    [REFERENCE_TYPE_TRUCK]: '/trucks/info/',
    [REFERENCE_TYPE_TRAILER]: '/trailers/info/',
    [REFERENCE_TYPE_LOAD]: '/loads/info/',
    [REFERENCE_TYPE_DRIVER]: '/drivers/info/',
    [REFERENCE_TYPE_FIXED_ASSET]: '/accounting-assets/',
    [REFERENCE_TYPE_MILEAGE_TAX_ENTRY]: '/fuel-report',
    [REFERENCE_TYPE_CUSTOMER]: '/customers/info/0/',
    [REFERENCE_TYPE_VENDOR]: '/vendors/info/0/',
    [REFERENCE_TYPE_CARRIER]: '/carriers/info/0/',
}

export const GROUP_MEMBER_TYPE_MEMBER = 2
export const GROUP_MEMBER_TYPE_MANAGER = 1

export const EXPIRY_FLEET_ITEM_TYPES = {
    1: "Truck",
    2: "Trailer"
}
export const EXPENSE_TYPE_EXPENSE = 2
export const EXPENSE_TYPE_BILL = 1

export const OCR_LOCATION_FORMAT_TYPES = ["TRUCKOCR_CHROBINSON_LOAD_INFORMATION_ORIGIN", "ADDRESS_CITY_STATE_ZIP", "ADDRESS_USA_CITY_STATE_ZIP", "TRUCKOCR_CHROBINSON_LOAD_INFORMATION_DESTINATION"]
export const OCR_DATE_TIME_FORMAT_TYPES = ["DATE", "TIME", "STAMP", "DATE_INTERVAL", "TIME_INTERVAL", "STAMP_INTERVAL"]
export const OCR_MONEY_FORMAT_TYPES = ["MONEY"]
export const OCR_MAIN_FRAGMENT_TYPES = ["DATE", "TIME", "MONEY", "MONEY_UNIT", "FLOAT", "EMAIL", "EMAIL_DOMAIN", "EMAIL_ACCOUNT"]
export const OCR_GENERIC_FORMAT_TYPES = ["TEXT", "SOLID", "UNSIGNED", "LINE", "WORD"]
export const OCR_LOCATION_FIELDS = ['CityName', 'StateID', 'PostalCode']
export const OCR_DATE_TIME_FIELDS = ['StopDate', 'StopTime', 'StopEndDate', 'StopEndTime']
export const OCR_MONEY_FIELDS = ['Price']
export const OCR_SPECIFIC_FIELDS = [...OCR_DATE_TIME_FIELDS, ...OCR_MONEY_FIELDS]

// Regex constants
export const REGEX_MENTION_IN_STRING = /@{{@([^}]+)}}/g;

export const RESOURCE_TABLE_SIMPLE_DEFAULT_OPTIONS = {style: {floatingActions: true, horizontalLines: true, isTableLayoutFixed: true}}

export const AGENT_EMPLOYMENT_STATUS = {
    1: "Active",
    2: "Paused",
    3: "Inactive",
    7: "Pending"
}