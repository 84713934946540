import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Resources from "../../../../data/services/resources";
import {createResource, deleteResource, getResource, updateResource} from "../../../../data/actions/resource";
import LocalStorage from "../../../../common/util/localStorage";
import {
    CREATE_PERM,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM, READ_PERM,
    UPDATE_PERM
} from "../../../../common/util/util-consts";
import {createSecondResource, getSecondResource} from "../../../../data/actions/secondResource";
import useQuery from "../../../../hooks/use-query";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {numberWithCommas} from "../../../../common/util/util-formaters";
import {showModal} from "../../../../data/actions/ui";
import {PlusIcon} from "@heroicons/react/24/outline";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import CellButton from "../../../../common/components/resource-table/table-components/cell-button";
import Card from "../../../../common/components/card";
import ResourceList from "../../../../common/components/layout/layout-components/resource-list";
import PageHeader from "../../../../common/components/layout/layout-components/page/page-header";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import CardTitle from "../../../../common/components/card/card-title";
import {Loader} from "../../../../common/components/loader";
import {
    checkPerm,
    getDefaultQueryFields,
    getProp,
    renderInvoiceStatusBadge
} from "../../../../common/util/util-helpers";


const RentalsBillingTab = ({translate, rentalInfo, isLoading, match}) => {
    /** Constants
     ================================================================= */
    const RentalID = getProp(match, 'params.id', 0)

    /** Store
     ================================================================= */
    const dispatch = useDispatch()

    // Payment Templates Store
    const paymentTemplateResource = useSelector((state) => state.resource);
    const paymentTemplateData = getProp(paymentTemplateResource.data, "data.list", [])
    const paymentTemplateCount = getProp(paymentTemplateResource.data, "count", 0)
    const paymentTemplateIsLoading = getProp(paymentTemplateResource, "isLoading", true);

    // Billing Summary Store
    const billingSummaryResource = useSelector((state) => state.secondResource);

    /** Helpers
     ================================================================= */
    const getPaymentTemplateFields = (item = null) => {
        const fieldTemplates = {
            Amount: new Field('Amount', '', [], false, 'money', {addContainerClass: 'col-span-full'}),
            StartDate: new Field('StartDate', '', [''], false, 'date',
                {hasActiveBadge: true, addContainerClass: 'col-span-6'},
                {
                    isClearable: true,
                    popperProps: {
                        strategy: 'fixed',
                        positionFixed: true
                    }
                }),
            LastPaymentDate: new Field('LastPaymentDate', '', [''], false, 'date',
                {hasActiveBadge: true, addContainerClass: 'col-span-6'},
                {
                    isClearable: true,
                    popperProps: {
                        strategy: 'fixed',
                        positionFixed: true
                    }
                }),

            AccountID: new Field(
                'AccountID',
                '',
                [''],
                false,
                'select-search',
                {
                    addContainerClass: "col-span-full",
                }, {
                    hasPortal: true,
                    menuPosition: 'fixed'
                }
            ),

            CapTypeID: new Field('CapTypeID', '', [''], false, 'select',
                {hasActiveBadge: true, addContainerClass: 'col-span-6'},
                {
                    values: {
                        1: 'Always',
                        2: 'Max Amount',
                        3: 'One Time',
                    },
                }),
            MaxAmount: new Field('MaxAmount', '', [], false, 'hidden', {
                addContainerClass: 'col-span-6',
                render: (item) => <div className="text-right">{numberWithCommas(item.MaxAmount)}</div>
            }),
            OneTimeAmount: new Field('OneTimeAmount', '', [], false, 'hidden', {
                addContainerClass: 'col-span-6',
                render: (item) => <div className="text-right">{numberWithCommas(item.OneTimeAmount)}</div>
            }),

            ScheduleFrequency: new Field('ScheduleFrequency', '', [], false, 'select', { addContainerClass: 'col-span-full' }),
            Note: new Field('Note', '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
        }

        if (item) {
            if (item.CapTypeID == 2) {
                fieldTemplates['MaxAmount'].type = 'money'
            }
            if (item.CapTypeID == 3) {
                fieldTemplates['OneTimeAmount'].type = 'money'
            }
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const getPaymentTemplateAddFields = (item = null) => {
        const fieldTemplates = {
            OfficeID: new Field(
                'OfficeID',
                '',
                [''],
                false,
                'select-search',
                {
                    addContainerClass: "col-span-full",
                }, {
                    hasPortal: true,
                    menuPosition: 'fixed'
                }
            ),
            ContactGroupID: new Field(
                'ContactGroupID',
                '',
                [''],
                false,
                'select-search',
                {
                    addContainerClass: "col-span-full",
                }, {
                    hasPortal: true,
                    menuPosition: 'fixed'
                }
            ),
            Date: new Field('Date', '', [''], false, 'date',
                {hasActiveBadge: true, addContainerClass: 'col-span-6'},
                {
                    isClearable: true,
                    popperProps: {
                        strategy: 'fixed',
                        positionFixed: true
                    }
                }),
            BookDate: new Field('BookDate', '', [''], false, 'date',
                {hasActiveBadge: true, addContainerClass: 'col-span-6'},
                {
                    isClearable: true,
                    popperProps: {
                        strategy: 'fixed',
                        positionFixed: true
                    }
                }),
            DueDate: new Field('DueDate', '', [''], false, 'date',
                {hasActiveBadge: true, addContainerClass: 'col-span-6'},
                {
                    isClearable: true,
                    popperProps: {
                        strategy: 'fixed',
                        positionFixed: true
                    }
                }),
            InternalNotes: new Field('InternalNotes', '', [], false, 'textarea', {addContainerClass: "col-span-3"}),
            ExternalNotes: new Field('ExternalNotes', '', [], false, 'textarea', {addContainerClass: "col-span-3"}),
            RefNumber: new Field('RefNumber', '', [], false, 'text',{addContainerClass: "col-span-full"}),
            Amount: new Field('Amount', '', [], false, 'money', {addContainerClass: 'col-span-full'}),
            AccountID: new Field(
                'AccountID',
                '',
                [''],
                false,
                'select-search',
                {
                    addContainerClass: "col-span-full",
                }, {
                    hasPortal: true,
                    menuPosition: 'fixed',
                    menuPlacement: 'top'
                }
            ),
        }

        if (rentalInfo && rentalInfo.ExternalNotesCustomer) {
            rentalInfo.ExternalNotes = rentalInfo.ExternalNotesCustomer
        }

        return fillFieldsFromData(fieldTemplates, Object.assign({}, item, rentalInfo))
    }

    const getBillingInvoiceFields = () => {
        return {
            InvoiceID: new Field('InvoiceID', '', [], false, 'text'),
            RefNumber: new Field('RefNumber', '', [], false, 'text'),
            Date: new Field('Date', '', [], false, 'date'),
            BookDate: new Field('BookDate', '', [], false, 'date'),
            DueDate: new Field('DueDate', '', [], false, 'date'),
            ChargeTo: new Field('ChargeTo', '', [], false, 'text', {
                render: (item) => {
                    if (item.ContactID) {
                        return (
                            <CellButton
                                onClick={() => checkPerm(Resources.Contacts,UPDATE_PERM) && handleViewContactClick(item.ContactID)}
                            >
                                {item.Contact}
                            </CellButton>
                        )
                    } else if (item.OrganizationID) {
                        return (
                            <CellButton
                                onClick={() => checkPerm(Resources.Organizations,READ_PERM) && handleViewOrganizationClick(item.OrganizationID)}
                            >
                                {item.Organization}
                            </CellButton>
                        )
                    } else {
                        return ""
                    }
                }
            }),
            InvoiceStatus: new Field('InvoiceStatus', '', [''], false, 'custom', {
                render: (it) => renderInvoiceStatusBadge(it)
            }),        Amount: new Field('Amount', '', [], false, 'money'),
            Balance: new Field('Balance', '', [], false, 'money', {
                render: (item) => <div>${numberWithCommas((item.Amount ?? 0) - (item.AmountTransferred ?? 0))}</div>
            }),
            ExternalNotes: new Field('ExternalNotes', '', [], false, 'textarea'),
            InternalNotes: new Field('InternalNotes', '', [], false, 'textarea'),
        }
    }

    const getPaymentTemplateResourcePath = () => {
        return Resources.RentalBillingPaymentTemplates
    }

    const getPaymentTemplateInvoiceResourcePath = () => {
        return Resources.RentalBillingPaymentTemplatesCreateInvoice
    }

    const getPaymentTemplatePrimaryKey = () => {
        return 'RentalBillingTemplateID'
    }

    const getBillingSummaryResourcePath = () => {
        return Resources.RentalBillingInvoices
    }


    /** UI Events
     ================================================================= */
    const handleViewContactClick = (contactID) => {
        dispatch(showModal("ViewContactCard", {ContactID: contactID}))
    }

    const handleViewOrganizationClick = (organizationID) => {
        dispatch(showModal('ViewCustomerCard', {OrganizationID: organizationID}));
    }

    const handleContactInfoClick = (contactID) => {
        dispatch(showModal("ViewContactCard", {ContactID: contactID}))
    }

    const handleTogglePaymentTemplateCreateModal = () => {
        setPaymentTemplateCreateModalOpen(!paymentTemplateCreateModalOpen)
    }

    const handleTogglePaymentTemplateEditModal = (item = null) => {
        setPaymentTemplateSelectedItem(item)
        setPaymentTemplateEditModalOpen(!paymentTemplateEditModalOpen)
    }

    const handleTogglePaymentTemplateAddModal = (item = null) => {
        setPaymentTemplateSelectedItem(item)
        setPaymentTemplateAddModalOpen(!paymentTemplateAddModalOpen)
    }

    const handleDeletePaymentTemplateClick = (item) => {
        setConfirmPaymentTemplateDialogData({
            isOpen: true,
            onClick: () => onConfirmDeletePaymentTemplateClick(item),
            type: "danger",
            content: (
                <span>
                    Are you sure you want to delete <span className="font-bold text-red-600">{item.AccountName}</span>?
                </span>
            ),
            title: translate("text.confirm_delete"),
            buttonLabel: translate("btn.delete")
        })
    }

    const onConfirmDeletePaymentTemplateClick = (item) => {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: Object.assign({[getPaymentTemplatePrimaryKey()]: item[getPaymentTemplatePrimaryKey()]}),
            notificationMessage: translate(`text.rental_payment_template_deleted`),
            resource: getPaymentTemplateResourcePath(),
            piggyResource: getPaymentTemplateResourcePath(),
            successMessage: translate("text.rental_payment_template_deleted"),
            errorMessage: true
        }));

        closePaymentTemplateConfirmDialog();
    }

    const closePaymentTemplateConfirmDialog = () => {
        setConfirmPaymentTemplateDialogData(Object.assign({}, confirmPaymentTemplateDialogData, {isOpen: false}))
    }

    const handlePaymentModalInputChange = (fieldsClone, name, value) => {
        fieldsClone = FieldsManager.updateField(fieldsClone, name, value)

        if (name === 'CapTypeID') {
            if (value == 2) {
                fieldsClone['MaxAmount'].type = 'money'

                fieldsClone['OneTimeAmount'].type = 'hidden'
                fieldsClone['OneTimeAmount'].value = ''
            }
            if (value == 3) {
                fieldsClone['OneTimeAmount'].type = 'money'

                fieldsClone['MaxAmount'].type = 'hidden'
                fieldsClone['MaxAmount'].value = ''
            }
            if (value == 1) {
                fieldsClone['MaxAmount'].type = 'hidden'
                fieldsClone['MaxAmount'].value = ''
                fieldsClone['OneTimeAmount'].type = 'hidden'
                fieldsClone['OneTimeAmount'].value = ''
            }
        }

        return fieldsClone
    }


    /** State
     ================================================================= */
    // Payment Templates Query
    const [paymentTemplateQueryFields, setPaymentTemplateQueryFields] = useQuery(getPaymentTemplateQueryFields(translate), 'rental-billing-tab-payments')
    const paymentTemplateQuery = Object.assign({},FieldsManager.getFieldKeyValues(paymentTemplateQueryFields), {
        RentalID: RentalID
    });

    // Payment Templates Modals
    const [paymentTemplateCreateModalOpen, setPaymentTemplateCreateModalOpen] = useState(false)
    const [paymentTemplateEditModalOpen, setPaymentTemplateEditModalOpen] = useState(false)
    const [paymentTemplateAddModalOpen, setPaymentTemplateAddModalOpen] = useState(false)
    const [confirmPaymentTemplateDialogData, setConfirmPaymentTemplateDialogData] = useState({});
    const [paymentTemplateSelectedItem, setPaymentTemplateSelectedItem] = useState(null)

    // Billing Summary Query
    const [billingSummaryQueryFields, setBillingSummaryQueryFields] = useQuery(getPaymentTemplateQueryFields(translate), 'rental-billing-tab-summary')
    const billingSummaryQuery = Object.assign({},FieldsManager.getFieldKeyValues(billingSummaryQueryFields), {
        RentalID: RentalID
    });

    // Billing Summary Modals
    const [billingSummaryCreateModalOpen, setBillingSummaryCreateModalOpen] = useState(false)
    const [billingSummaryEditModalOpen, setBillingSummaryEditModalOpen] = useState(false)
    const [billingSummaryConfirmModalOpen, setBillingSummaryConfirmModalOpen] = useState(false)

    /** Data events
     ================================================================= */
    const onFetchPaymentTemplate = () => {
        dispatch(getResource({
            user: LocalStorage.get("user"),
            query: paymentTemplateQuery,
            resource: getPaymentTemplateResourcePath()
        }))
    }

    const onFetchBillingSummary = () => {
        dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: billingSummaryQuery,
            resource: getBillingSummaryResourcePath()
        }))
    }

    /** Life cycle
     ================================================================= */
    useEffect(() => {
        onFetchPaymentTemplate()
    }, [paymentTemplateQueryFields])

    useEffect(() => {
        onFetchBillingSummary()
    }, [billingSummaryQueryFields])

    /** Render
     ================================================================= */

    return (
        <div className={"mt-5"}>
            <div className="grid grid-cols-12 gap-5">
                <div className={'col-span-9'}>
                    <Card bodyClass={'p-2'}>
                        <PageHeader
                            title={translate("page_title.PaymentTemplates")}
                            buttons={checkPerm(getPaymentTemplateResourcePath(), CREATE_PERM) ? [
                                {
                                    label: translate("btn.create"),
                                    type: "primary",
                                    onClick: handleTogglePaymentTemplateCreateModal
                                }
                            ] : []}
                        />

                        <ResourceList
                            fields={getPaymentTemplateFields()}

                            resource={paymentTemplateResource}
                            listPath={'rental-billing-tab-payments'}
                            isLoading={paymentTemplateIsLoading}
                            queryFields={paymentTemplateQueryFields}
                            setQueryFields={setPaymentTemplateQueryFields}
                            dispatch={dispatch}
                            query={paymentTemplateQuery}
                            onRefreshTable={onFetchPaymentTemplate}
                            translate={translate}
                            selects={getSelects()}

                            onRowClick={checkPerm(getPaymentTemplateResourcePath(), UPDATE_PERM) && handleTogglePaymentTemplateEditModal}
                            actions={[{
                                icon: PlusIcon,
                                title: translate("btn.CreateRentalInvoice"),
                                action: (item) => handleTogglePaymentTemplateAddModal(item)
                            }]}
                            onEdit={checkPerm(getPaymentTemplateResourcePath(), UPDATE_PERM) && handleTogglePaymentTemplateEditModal}
                            onDelete={checkPerm(getPaymentTemplateResourcePath(), DELETE_PERM) && handleDeletePaymentTemplateClick}
                        >
                        </ResourceList>
                    </Card>

                    <ModalSaveResource
                        dialogWidth="max-w-3xl"
                        fields={getPaymentTemplateFields()}
                        show={paymentTemplateCreateModalOpen}
                        title={translate("modal.heading.CreateNewPaymentTemplate")}
                        onClose={handleTogglePaymentTemplateCreateModal}
                        addFieldContainerClass={"col-span-full"}
                        addBodyClass={"pb-32 text-tm-gray-900"}
                        isLoading={isLoading}
                        resource={paymentTemplateResource}
                        metadata={getSelects()}
                        translate={translate}
                        onSubmit={(params) => {
                            if (params) {
                                params.RentalID = RentalID
                                dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    query: paymentTemplateQuery,
                                    resource: getPaymentTemplateResourcePath(),
                                    piggyResource: getPaymentTemplateResourcePath(),
                                    successMessage: translate("text.payment_template_created_successfully"),
                                }));
                                handleTogglePaymentTemplateCreateModal()
                            }
                        }}
                        onInputChange={handlePaymentModalInputChange}
                    />

                    <ModalSaveResource
                        dialogWidth="max-w-7xl"
                        fields={getPaymentTemplateFields(paymentTemplateSelectedItem)}
                        show={paymentTemplateEditModalOpen}
                        title={translate("modal.heading.EditPaymentTemplate")}
                        onClose={handleTogglePaymentTemplateEditModal}
                        addFieldContainerClass={"col-span-full"}
                        addBodyClass={"pb-32 text-tm-gray-900"}
                        isLoading={isLoading}
                        resource={paymentTemplateResource}
                        metadata={getSelects()}
                        translate={translate}
                        onSubmit={(params) => {
                            if (params) {
                                params[getPaymentTemplatePrimaryKey()] = paymentTemplateSelectedItem[getPaymentTemplatePrimaryKey()]

                                dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    query: paymentTemplateQuery,
                                    resource: getPaymentTemplateResourcePath(),
                                    piggyResource: getPaymentTemplateResourcePath(),
                                    successMessage: translate("text.payment_template_updated_successfully"),
                                }));
                                handleTogglePaymentTemplateEditModal()
                            }
                        }}
                        onInputChange={handlePaymentModalInputChange}
                    />

                    <ModalSaveResource
                        dialogWidth="max-w-7xl"
                        fields={getPaymentTemplateAddFields(paymentTemplateSelectedItem)}
                        show={paymentTemplateAddModalOpen}
                        title={translate("modal.heading.CreateRentalInvoice")}
                        onClose={handleTogglePaymentTemplateAddModal}
                        addFieldContainerClass={"col-span-full"}
                        addBodyClass={"pb-32 text-tm-gray-900"}
                        isLoading={isLoading}
                        resource={paymentTemplateResource}
                        metadata={getSelects()}
                        translate={translate}
                        onSubmit={(params) => {
                            if (params) {
                                params[getPaymentTemplatePrimaryKey()] = paymentTemplateSelectedItem[getPaymentTemplatePrimaryKey()]
                                params.RentalID = RentalID

                                dispatch(createSecondResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    query: paymentTemplateQuery,
                                    resource: getPaymentTemplateInvoiceResourcePath(),
                                    piggyResource: getBillingSummaryResourcePath(),
                                    successMessage: translate("text.rental_invoice_created"),
                                }));
                                handleTogglePaymentTemplateAddModal()
                            }
                        }}
                        onInputChange={handlePaymentModalInputChange}
                    />

                    <ModalConfirm
                        show={confirmPaymentTemplateDialogData?.isOpen ?? false}
                        type={confirmPaymentTemplateDialogData?.type}
                        content={confirmPaymentTemplateDialogData?.content}
                        title={confirmPaymentTemplateDialogData?.title}
                        onButtonClick={confirmPaymentTemplateDialogData?.onClick}
                        closeButtonLabel={confirmPaymentTemplateDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                        buttonLabel={confirmPaymentTemplateDialogData?.buttonLabel}
                        translate={translate}
                        onClose={closePaymentTemplateConfirmDialog}
                    />
                    
                </div>
                <div className={'col-span-3 h-full'}>
                    <Card bodyClass={'p-2 h-full'} addClass={'h-full'}>
                        <h1 className="text-2xl text-tm-gray-900 flex gap-2 items-center"><span>Billing Summary</span></h1>

                        {getProp(billingSummaryResource, 'isLoading', true) ? (
                            <div className={'relative mt-12'}>
                                <Loader />
                            </div>
                        ) : (
                            <>
                                <div className={'mt-3'}>
                                    <dt className={"flex text-sm font-medium text-tm-gray-500"}>
                                        {translate("field.OpenInvoicesAmount")}
                                    </dt>

                                    <dd className={"mt-1 text-sm text-tm-gray-700"}>
                                        <div className="">${numberWithCommas(getProp(billingSummaryResource, 'data.BillingSummary.OpenInvoicesAmount', 0))}</div>
                                    </dd>
                                </div>
                                <div className={'mt-3'}>
                                    <dt className={"flex text-sm font-medium text-tm-gray-500"}>
                                        {translate("field.PaidInvoicesAmount")}
                                    </dt>

                                    <dd className={"mt-1 text-sm text-tm-gray-700"}>
                                        <div className="">${numberWithCommas(getProp(billingSummaryResource, 'data.BillingSummary.PaidInvoicesAmount', 0))}</div>
                                    </dd>
                                </div>
                                <div className={'mt-3'}>
                                    <dt className={"flex text-sm font-medium text-tm-gray-500"}>
                                        {translate("field.PastDueInvoicesAmount")}
                                    </dt>

                                    <dd className={"mt-1 text-sm text-tm-gray-700"}>
                                        <div className="">${numberWithCommas(getProp(billingSummaryResource, 'data.BillingSummary.PastDueInvoicesAmount', 0))}</div>
                                    </dd>
                                </div>
                            </>
                        )}
                    </Card>

                </div>
            </div>

            <Card bodyClass={'p-2 '} addClass={'mt-5'}>
                <CardTitle
                    title={translate("page_title.Invoices")}
                />

                <ResourceList
                    fields={getBillingInvoiceFields()}

                    listPath={'rental-billing-tab-summary'}
                    resource={billingSummaryResource}
                    queryFields={billingSummaryQueryFields}
                    setQueryFields={setBillingSummaryQueryFields}
                    dispatch={dispatch}
                    query={billingSummaryQuery}
                    onRefreshTable={onFetchBillingSummary}
                    translate={translate}
                    selects={getSelects()}
                >
                </ResourceList>
            </Card>

        </div>
    )
}

function getSelects() {
    return {
        CapTypeID: {
            1: 'Always',
            2: 'Max Amount',
            3: 'One Time',
        },
        ScheduleFrequency: {
            'Weekly': 'Weekly',
            'Semi-Weekly': 'Semi-Weekly',
            'Monthly': 'Monthly',
        },
        AccountID: {
            api: 'api/' + Resources.AccountsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.AccountID,
                label: item.AccountNumber + ' ' + item.AccountName,
                metadata: item
            })
        },
        OfficeID: {
            api: 'api/' + Resources.OfficesQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.OfficeName,
                value: item.OfficeID
            })
        },
        ContactGroupID: {
            api: 'api/' + Resources.ContactGroupsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.ContactGroupName,
                value: item.ContactGroupID
            })
        },
    }
}

const getPaymentTemplateQueryFields = (translate) => {
    return (
        Object.assign(
            getDefaultQueryFields('', translate),
            {
                query: new Field('query', '', [''], false, 'search', {
                    hideLabel: true,
                    hasActiveBadge: true,
                    labelType: "float",
                    addContainerClass: 'col-span-3'
                }, {placeholder: translate("text.search")}),
            }
        )
    )
}

export default RentalsBillingTab;