import React, {useRef, useState} from "react";
import {getDocumentTypesFor, getProp} from "../../../common/util/util-helpers";
import {useDispatch, useSelector} from "react-redux";
import Resources from "../../../data/services/resources";
import {Field, FieldsManager} from "../../../data/services/fields";
import InformationCircleIcon from "@heroicons/react/20/solid/esm/InformationCircleIcon";
import {createResource} from "../../../data/actions/resource";
import LocalStorage from "../../../common/util/localStorage";
import {showGlobalModal, showModal} from "../../../data/actions/ui";
import RentalsCreateInfoTab from "../../../views/rent-lease/rentals-create/rentals-create-info-tab";
import {ArrowLeftIcon} from "@heroicons/react/24/outline";
import Layout from "../../../common/components/layout";
import FieldOptions from "../../../common/components/fields/field-options";
import PageTabWithFooter from "../../../common/components/layout/layout-components/page/page-tab-with-footer";
import NavResponsive from "../../../common/components/nav-responsive";
import CreateDocumentsTab from "../../../common/components/tabs/documents-tab/documents-tab-create";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import ModalCreateSuccessNew from "../../../common/components/modal/create-success-modal-new";
import {RENT_STATUS_RENTED} from "../../../common/util/util-consts";
import Card from "../../../common/components/card";
import ImagesCreateTab from "../../../common/components/tabs/documents-tab/create-images";
import DocumentsCreateTab from "../../../common/components/tabs/documents-tab/create-documents";

const RentalsCreate = ({history, match, translate}) => {

    /** Helpers
     ================================================================= */
    const getBasicFields = () => {
        return {
            RentTypeID: new Field('RentTypeID', '', ['required'], false, 'select',{addContainerClass: "col-span-6"}),
            RefNumber: new Field('RefNumber', '', [''], false, 'text',{addContainerClass: "col-span-6"}),
            RentTo: new Field('RentTo', 1, [''], false, 'button-group', {
                data: {1: translate('btn.contact'), 2: translate('btn.organization')},
                addContainerClass: 'col-span-full'
            }),
            RentContactID: new Field(
                'RentContactID',
                '',
                [''],
                false,
                'select-search',
                {
                    addContainerClass: "col-start-1 col-span-6",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[{
                                    icon: InformationCircleIcon,
                                    onClick: () => handleContactInfoClick(it?.value?.value),
                                    isVisible: !!it?.value
                                }]}
                            />
                        )
                    }
                },
            ),
            RentOrganizationID: new Field(
                'RentOrganizationID',
                '',
                [''],
                true,
                'select-search',
                {
                    addContainerClass: "col-span-6",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[{
                                    icon: InformationCircleIcon,
                                    onClick: () => handleOrganizationInfoClick(it?.value?.value),
                                    isVisible: !!it?.value
                                }]}
                            />
                        )
                    }
                },
            ),
            PayTo: new Field('PayTo', 1, [''], false, 'button-group', {
                data: {1: translate('btn.contact'), 2: translate('btn.organization')},
                addContainerClass: 'col-span-full'
            }),
            PayContactID: new Field(
                'PayContactID',
                '',
                [''],
                false,
                'select-search',
                {
                    addContainerClass: "col-start-1 col-span-6",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[{
                                    icon: InformationCircleIcon,
                                    onClick: () => handleContactInfoClick(it?.value?.value),
                                    isVisible: !!it?.value
                                }]}
                            />
                        )
                    }
                },
            ),
            PayOrganizationID: new Field(
                'PayOrganizationID',
                '',
                [''],
                true,
                'select-search',
                {
                    addContainerClass: "col-span-6",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[{
                                    icon: InformationCircleIcon,
                                    onClick: () => handleOrganizationInfoClick(it?.value?.value),
                                    isVisible: !!it?.value
                                }]}
                            />
                        )
                    }
                },
            ),
            RentalContractID: new Field('RentalContractID', '', [''], false, 'select-search', {addContainerClass: 'col-span-full'}),
            InternalNotes: new Field("InternalNotes", '', [''], false, "textarea", { addContainerClass: "col-start-1 col-span-6"}),
            ExternalNotesCustomer: new Field("ExternalNotesCustomer", '', [''], false, "textarea", { addContainerClass: "col-span-6"})
        }
    }

    const getAssetFields = () => {
        return {
            AssetType: new Field('AssetType', 1, [''], false, 'button-group', {
                data: {1: translate('btn.truck'), 2: translate('btn.trailer')},
                addContainerClass: 'col-span-full'
            }),
            TruckID: new Field(
                'TruckID',
                '',
                [''],
                false,
                'select-search',
                {
                    addContainerClass: 'col-span-6',
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    /*{
                                        icon: ListBulletIcon,
                                        onClick: () => handleTruckWoClick(it?.value?.metadata),
                                        isVisible: !!it?.value?.metadata
                                    },*/
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => handleTruckInfoClick(it?.value?.value),
                                        isVisible: !!it?.value
                                    }
                                ]}
                            />
                        )
                    }
                },
            ),
            TrailerID: new Field(
                'TrailerID',
                '',
                [''],
                true,
                'select-search',
                {
                    addContainerClass: 'col-span-6',
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    /*{
                                        icon: ListBulletIcon,
                                        onClick: () => handleTrailerWoClick(it?.value?.metadata),
                                        isVisible: !!it?.value?.metadata
                                    },*/
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => handleTrailerInfoClick(it?.value?.value),
                                        isVisible: !!it?.value
                                    }]}
                            />
                        )
                    }
                },
            ),
        }
    }

    const getTabs = () => {
        return [
            {
                name: 'Info',
                resource: Resources.Rental,
                current: true,
                visible: true
            },
            {
                name: 'Images',
                resource: Resources.RentalImages,
                current: false,
                visible: true
            },
            {
                name: 'Documents',
                resource: Resources.RentalDocuments,
                current: false,
                visible: true
            }
        ]
    }

    /** Constants & Hooks
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    let isItemCreatedRef = useRef(false);

    const isLoading = getProp(resource, "isLoading", false);
    const data = getProp(resource, "data", {});

    const [fieldsBasic, setFieldsBasic] = useState(getBasicFields());
    const [fieldsAssets, setFieldsAssets] = useState(getAssetFields());
    const [isStateDirty, setIsStateDirty] = useState(false);

    const [tabs, setTabs] = useState(getTabs());
    const currentTab = tabs.find(it => it.current);

    const [dataDocuments, setDataDocuments] = useState([]);

    const layoutRef = useRef();
    const [isLayoutScrolled, setIsLayoutScrolled] = useState(false)

    const [existingRentalWarning, setExistingRentalWarning] = useState(false)
    const [existingRentalID, setExistingRentalID] = useState(null)

    const [dataImages, setDataImages] = useState([]);

    /** UI Events
     ================================================================= */
    const handleBasicInputChange = (name, value) => {
        let fieldsBasicUpdate = Object.assign({}, fieldsBasic);
        fieldsBasicUpdate = FieldsManager.updateField(fieldsBasicUpdate, name, value);

        if (name === "RentTo") {
            fieldsBasicUpdate.RentContactID.disabled = value === 2
            fieldsBasicUpdate.RentContactID.value = value === 1 ? fieldsBasicUpdate.RentContactID.value : "";
            fieldsBasicUpdate.RentContactID.validate = value === 1 ? ['required'] : ['']

            fieldsBasicUpdate.RentOrganizationID.disabled = value === 1
            fieldsBasicUpdate.RentOrganizationID.value = value === 2 ? fieldsBasicUpdate.RentOrganizationID.value : "";
            fieldsBasicUpdate.RentOrganizationID.validate = value === 2 ? ['required'] : ['']
        }

        if (name === "PayTo") {
            fieldsBasicUpdate.PayContactID.disabled = value === 2
            fieldsBasicUpdate.PayContactID.value = value === 1 ? fieldsBasicUpdate.PayContactID.value : "";
            fieldsBasicUpdate.PayContactID.validate = value === 1 ? ['required'] : ['']

            fieldsBasicUpdate.PayOrganizationID.disabled = value === 1
            fieldsBasicUpdate.PayOrganizationID.value = value === 2 ? fieldsBasicUpdate.PayOrganizationID.value : "";
            fieldsBasicUpdate.PayOrganizationID.validate = value === 2 ? ['required'] : ['']
        }

        setFieldsBasic(fieldsBasicUpdate);
        setIsStateDirty(true);
    }

    const handleAssetsInputChange = (name, value) => {
        let fieldsAssetsUpdate = Object.assign({}, fieldsAssets);
        fieldsAssetsUpdate = FieldsManager.updateField(fieldsAssetsUpdate, name, value);

        if (name === "AssetType") {
            if (fieldsAssetsUpdate['TruckID'].value && value === 2) setExistingRentalWarning(false)
            if (fieldsAssetsUpdate['TrailerID'].value && value === 1) setExistingRentalWarning(false)

            fieldsAssetsUpdate.TruckID.disabled = value === 2
            fieldsAssetsUpdate.TruckID.value = value === 1 ? fieldsAssetsUpdate.TruckID.value : "";
            fieldsAssetsUpdate.TruckID.validate = value === 1 ? ['required'] : ['']

            fieldsAssetsUpdate.TrailerID.disabled = value === 1
            fieldsAssetsUpdate.TrailerID.value = value === 2 ? fieldsAssetsUpdate.TrailerID.value : "";
            fieldsAssetsUpdate.TrailerID.validate = value === 2 ? ['required'] : ['']
        }

        if (name === "TruckID" || name === "TrailerID") {
            const isAssetInActiveRent = value.metadata.RentStatusID === RENT_STATUS_RENTED
            setExistingRentalWarning(isAssetInActiveRent)
            if (isAssetInActiveRent) setExistingRentalID(value.metadata.RentalID)
        }

        setFieldsAssets(fieldsAssetsUpdate);
        setIsStateDirty(true);
    }

    const handleTabChange = (resource) => {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        )
    }

    const handleContactInfoClick = (contactID) => {
        dispatch(showModal("ViewContactCard", {ContactID: contactID}))
    }

    const handleOrganizationInfoClick = (organizationID) => {
        dispatch(showModal("ViewCustomerCard", {OrganizationID: organizationID}))
    }

    const handleTruckInfoClick = (truckID) => {
        dispatch(showGlobalModal("ViewTruckCard", truckID))
    }

    const handleTrailerInfoClick = (trailerID) => {
        dispatch(showGlobalModal("ViewTrailerCard", trailerID))
    }

    const handleLayoutScroll = () => {
        if (layoutRef?.current.scrollTop > 100 && !isLayoutScrolled) {
            setIsLayoutScrolled(true)
        }

        if (layoutRef?.current.scrollTop <= 100 && isLayoutScrolled) {
            setIsLayoutScrolled(false)
        }
    }

    const handleScrollLayoutOnTop = () => {
        if (layoutRef?.current) {
            layoutRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    const handleUploadImage = (files) => {
        let fields = dataImages
        fields = fields.concat(files)
        setDataImages(fields)
    }

    const handleDeleteImage= (item) => {
        let fields = dataImages.filter(it => it.preview !== item.preview)
        setDataImages(fields)
    }

    const handleUploadDocument = (files) => {
        let fields = dataDocuments
        fields = fields.concat(files)
        setDataDocuments(fields)
    }

    const handleDeleteDocument = (item) => {
        let fields = dataDocuments.filter(it => it.preview !== item.preview)
        setDataDocuments(fields)
    }
    /** Data Events
     ================================================================= */
    const onSubmitClick = () => {
        const fieldsBasicValidate = FieldsManager.validateFields(fieldsBasic);
        const fieldsAssetsValidate = FieldsManager.validateFields(fieldsAssets);

        if (
            FieldsManager.checkFieldsForErrors(fieldsBasicValidate) &&
            FieldsManager.checkFieldsForErrors(fieldsAssetsValidate)
        ) {
            let params = Object.assign({},
                FieldsManager.getFieldKeyValues(fieldsBasicValidate),
                FieldsManager.getFieldKeyValues(fieldsAssetsValidate),
            )

            let imageParams = {}
            imageParams = dataImages.map((it, index) => imageParams[index] = it.params)

            if (dataImages.length === 1) {
                imageParams = imageParams[0]
            }

            dispatch(createResource({
                user: LocalStorage.get('user'),
                params: params,
                fileParams: imageParams,
                document: dataDocuments,
                customID: 'RentalID',
                query: {},
                resource: Resources.Rental,
                file: dataImages,
                fileResource: Resources.RentalImages,
                documentResource: Resources.RentalDocuments,
                piggyResource: "work-orders/rentals"
            }))
            isItemCreatedRef.current = true;
        } else {
            setFieldsBasic(fieldsBasicValidate)
            setFieldsAssets(fieldsAssetsValidate)
        }
    }

    /** Component body
     ================================================================= */
    return (
        <Layout
            history={history}
            match={match}
            translate={translate}
            layoutRef={layoutRef}
            onScroll={handleLayoutScroll}
        >
            <PageTabWithFooter
                isLoading={isLoading}
                buttons={
                    [
                        {
                            className: "btn btn-primary",
                            onClick: () => onSubmitClick(),
                            disabled: !isStateDirty,
                            label: translate("btn.save")
                        },
                        {
                            className: "btn btn-outline",
                            disabled: !isStateDirty,
                            onClick: () => {
                                setFieldsBasic(getBasicFields());
                                setFieldsAssets(getAssetFields());
                                setIsStateDirty(false)
                            },
                            label: translate("btn.cancel")
                        },
                    ]
                }
            >
                <div>
                    <div className={"sticky"}>
                        <PageHeader
                            title={translate("Create rental")}
                            removeBorder={true}
                            ButtonsLeading={[
                                {
                                    iconLeading: ArrowLeftIcon,
                                    className: "btn btn-header m-2",
                                    onClick: () => history.push('/rentals/'),
                                    title: "Go back to rentals"
                                }
                            ]}
                        />

                        <NavResponsive
                            addClass="px-4 sm:px-6 md:px-8 h-9"
                            tabs={tabs}
                            onTabChange={handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {currentTab.name === "Info" && (
                        <RentalsCreateInfoTab
                            data={data}
                            isLoading={isLoading}
                            dispatch={dispatch}
                            getResourcePath={currentTab.resource}
                            translate={translate}
                            history={history}
                            handleBasicInputChange={handleBasicInputChange}
                            fieldsBasic={fieldsBasic}
                            handleAssetsInputChange={handleAssetsInputChange}
                            fieldsAssets={fieldsAssets}
                            isLayoutScrolled={isLayoutScrolled}
                            scrollToTop={handleScrollLayoutOnTop}
                            existingRentalWarning={existingRentalWarning}
                            existingRentalID={existingRentalID}
                        />
                    )}

                    {currentTab.name === "Images" && (
                        <Card>
                            <ImagesCreateTab
                                fields={dataImages}
                                translate={translate}

                                dialogTitle={'Upload images'}

                                handleUploadDocument={handleUploadImage}
                                handleDeleteDocument={handleDeleteImage}
                            />
                        </Card>
                    )}

                    {currentTab.name === "Documents" && (
                        <Card>
                            <DocumentsCreateTab
                                fields={dataDocuments}
                                translate={translate}

                                dialogTitle={'Rental documents'}

                                documentTypes={getDocumentTypesFor("IsWorkOrderDocument")}
                                handleUploadDocument={handleUploadDocument}
                                handleDeleteDocument={handleDeleteDocument}
                            />
                        </Card>
                    )}
                </div>

                <ModalCreateSuccessNew
                    resource={resource}
                    position={"center"}
                    successText={(createdResourceAutoCounter) => "Rental #" + createdResourceAutoCounter + " created successfully!"}
                    dispatch={dispatch}
                    translate={translate}
                    isItemCreatedRef={isItemCreatedRef}

                    buttons={(createdResourceID, closeSuccessDialog, createdResourceAutoCounter) => [
                        {
                            label: translate("btn.create_new_rental"),
                            onClick: closeSuccessDialog
                        },
                        {
                            label: translate("btn.edit_rental", [createdResourceAutoCounter]),
                            onClick: () => history.push("/rentals/" + createdResourceID)
                        },
                        {
                            label: translate("btn.go_back_to_rentals_list"),
                            onClick: () => history.push("/rentals/")
                        }
                    ]}
                />
            </PageTabWithFooter>
        </Layout>
    )
}

export default RentalsCreate;
