import React, {useEffect, useState} from "react";
import {Field, FieldsManager} from "../../../data/services/fields";
import {
    getDefaultQueryFields,
    resourceIsCreated,
    resourceIsUpdated,
    resourceIsDeleted
} from "../../../common/util/util-helpers";
import {getUser} from "../../../common/util/util-auth"
import Resources from "../../../data/services/resources";
import {useDispatch, useSelector} from "react-redux";
import {createResource, deleteResource, getResource, updateResource} from "../../../data/actions/resource";
import LocalStorage from "../../../common/util/localStorage";
import useQuery from "../../../hooks/use-query";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ResourceList from "../../../common/components/layout/layout-components/resource-list";

export default function RentalContractsSingleAnnexTab({translate, isCreateDialogOpen, setIsCreateDialogOpen, id}) {

    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = resource.isLoading ?? false;

    const [isStateDirty, setIsStateDirty] = useState(false);
    const [fields, setFields] = useState(getFields({}));
    const [selectedItem, setSelectedItem] = useState({});
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
    /** Constants
     ================================================================= */
    const primaryField = "RentalContractID";
    const resourcePath = Resources.WorkOrdersRentalContractsAnnex;

    const listStoragePath = resourcePath + "_list";

    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), listStoragePath);
    let query = Object.assign({}, FieldsManager.getFieldKeyValues(queryFields));
    query.id =id;

    /** Data Events
     ================================================================= */
    function fetchTabData() {
        dispatch(getResource({
            user: getUser(),
            resource: resourcePath,
            query: query
        }));
    }

    function handleCreateResource(params) {
        dispatch(createResource({
            user: LocalStorage.get('user'),
            params: params,
            query: query,
            resource: resourcePath,
            piggyResource: resourcePath,
            successMessage: translate("text.annex_created"),
        }));
    }

    function handleUpdateResource(params) {
        dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: params,
            query: query,
            resource: resourcePath,
            piggyResource: resourcePath,
            successMessage: translate("text.annex_updated"),
        }));
    }

    function handleDeleteResource() {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: Object.assign(query, {
                id: id,
                RentalContractAnnexID: selectedItem.RentalContractAnnexID
            }),
            resource: resourcePath,
            piggyResource: resourcePath,
            successMessage: translate("text.annex_deleted"),
        }));
    }

    /** UI Events
     ================================================================= */
    function handleInputChange(name, value) {
        const fieldsUpdate = Object.assign({}, fields);
        fieldsUpdate[name].errorMessage = "";
        setFields(fieldsUpdate);
        setIsStateDirty(true);
    }

    function handleEditClick(item) {
        setSelectedItem(item);
        setIsCreateDialogOpen(true);
    }

    function handleCloseModal() {
        setIsCreateDialogOpen(false);
        setSelectedItem({});
    }

    function handleDeleteClick(item) {
        setIsConfirmDeleteDialogOpen(true);
        setSelectedItem(item);
    }

    /** Helper functions
     ================================================================= */
    function getFields(item = {}) {
        const fieldTemplates = {
            RentalContractAnnexID: new Field('RentalContractAnnexID', "", [], false, 'hidden', {hideTable: true}),
            RentalContractID: new Field('RentalContractID', id, [], false, 'hidden', {hideTable: true}),
            RefNumber: new Field('RefNumber', '', ['required'], false, 'text', {addContainerClass:"col-span-full"}),
            StartDate: new Field('StartDate', '', [], false, 'date', {addContainerClass: "col-span-6"}),
            EndDate: new Field('EndDate', '', [], false, 'date', {addContainerClass: "col-span-6"}),
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass:"col-span-full"}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    function getQueryFields() {
        return (
            Object.assign(
                getDefaultQueryFields(primaryField, translate),
            )
        )
    }

    /** Life Cycle
     ================================================================= */
    useEffect(() => {
        fetchTabData();
    }, [])

    useEffect(() => {
        if (resourceIsUpdated(resource) || resourceIsCreated(resource)) {
            handleCloseModal();
        }

        if (resourceIsDeleted(resource)) {
            setIsConfirmDeleteDialogOpen(false);
        }
    }, [resource]);
    /** Component Body
     ================================================================= */
    return (
        <>
            <ResourceList
                fields={getFields({})}
                resource={resource}
                isLoading={isLoading}
                queryFields={queryFields}
                setQueryFields={setQueryFields}
                listPath={listStoragePath}
                dispatch={dispatch}
                query={query}
                onRefreshTable={fetchTabData}
                fetchData={fetchTabData}
                translate={translate}
                selects={{}}
                tableKey={"primaryField"}

                onEdit={handleEditClick}
                onDelete={handleDeleteClick}

                tableDefaults={{
                    behaviour: {
                        canAdjustWidth: true
                    },
                    style: {
                        condensed: true,
                        isGPUAccelerated: true
                    }
                }}
            />

            <ModalSaveResource
                show={isCreateDialogOpen}
                dialogWidth="max-w-md"

                fields={getFields(selectedItem)}
                resource={resource}

                title={
                    selectedItem.RentalContractAnnexID
                        ? translate("modal.heading.UpdateNewAnnex")
                        : translate("modal.heading.CreateNewAnnex")
                }
                onClose={handleCloseModal}
                addFieldContainerClass={"col-span-full"}
                bodyClass={"grid grid-cols-12 gap-4 px-4 pt-4 pb-6"}
                isLoading={isLoading}

                metadata={{}}
                translate={translate}
                onSubmit={(params) => {
                    if (params) {
                        if (params.RentalContractAnnexID) {
                            handleUpdateResource(params);
                        } else {
                            handleCreateResource(params);
                        }
                    }
                }}
            />

            <ModalConfirm
                show={isConfirmDeleteDialogOpen}
                type="danger"
                text={"Are you sure you want to delete addendum with reference number " + (selectedItem.RefNumber ?? "0") + "?"}
                title="Confirm delete"
                textClassName={"word-break"}
                onConfirm={handleDeleteResource}
                buttonLabel={translate("btn.delete")}
                translate={translate}
                closeButtonLabel={translate("btn.cancel")}
                onClose={() => {
                    setIsConfirmDeleteDialogOpen(false);
                    setSelectedItem({});
                }}

            />
        </>
    )
}