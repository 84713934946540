import jwt_decode from "jwt-decode";
import Cookie from "./cookie";
import LocalStorage from "./localStorage";
import {getProp} from "../common/util/util-helpers";
import {_ENV_MODE} from "./env";
import {getCurrentTimeSeconds} from "./util";

export function getDecodedJWTJson() {
    let token = getJWT().access_token;
    return token ? jwt_decode(token) : {}
}

export function saveJWTToken(tokenData) {
    ["access_token", "expires_in", "refresh_token", "scope", "token_type"].forEach(key => {
        Cookie.setCookie(
            key,
            tokenData[key],
            tokenData?.expires_in ?? -1
        );
    });

    Cookie.setCookie(
        "wo_truck_idle_modal_is_open",
        "false",
        -1
    );
}

export function clearCookies() {
    ["access_token", "expires_in", "refresh_token", "scope", "token_type", "wo_truck_idle_modal_is_open", "wo_truck_idle_modal_timeout"].forEach(it => {
        Cookie.setCookie(
            it,
            "",
            -1
        );
    })
}

export function getUser(keys, defaultVal) {
    const user = LocalStorage.get('user');

    if (!keys && !defaultVal) {
        return user;
    }

    if (keys) {
        return getProp(user, keys, defaultVal);
    }

    return defaultVal;
}

export function getJWT() {
    return ["access_token", "expires_in", "refresh_token", "scope", "token_type"]
        .reduce((memo, it) => {
            memo[it] = Cookie.getCookie(it + "_" + _ENV_MODE);

            return memo;
        }, {});
}

export function isTokenExpired(token) {
    const decoded = jwt_decode(token);
    const current = getCurrentTimeSeconds();
    const expires = decoded.exp ?? 0;
    return expires < current;
}

