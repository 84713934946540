import {render} from "react-dom";
import {createElement} from "react";
import App from "./app";
import "./common/styles/variables.css";
import "./common/styles/style.css";
import "./common/styles/tooltip-style.css";
import "./styles/react-leaflet-markercluster.css";
import "./styles/react-leaflet.css";
import * as serviceWorker from "./serviceWorker";
import enLocaleData from "./assets/locale/en.json";
import woLocaleData from "./assets/locale/en-wo.json";
import {_ENV_MODE} from "./util/env";
import * as Sentry from "@sentry/react";
// import generalJSON from "./assets/locale/general.json";
// import authJSON from "./assets/locale/auth.json";
// import contactsJSON from "./assets/locale/contacts.json";
// import companiesJSON from "./assets/locale/companies.json";
// import myCompanyJSON from "./assets/locale/my-company.json";
// import placesJSON from "./assets/locale/places.json";

/*
 * Globals
 =========================================================================================*/
window._DEV_MODE = (_ENV_MODE === 'local');

if (_ENV_MODE === 'prod' || _ENV_MODE === 'production') {
    Sentry.init({
        dsn: "https://779dd0903e2ec776acdad63e08b210b9@o4505750688235520.ingest.us.sentry.io/4506881979842560",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: true,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 0.2,
        // Session Replay
        replaysSessionSampleRate: 0.2,
        replaysOnErrorSampleRate: 1.0,
        release: "truck-rental-frontend@" + process.env.REACT_APP_VERSION + "+" + process.env.REACT_APP_COMMIT_HASH,
    });
}

/*
 * Locale data preload
 =====================================================================================*/
//const localeData = {...generalJSON, ...authJSON, ...contactsJSON, ...companiesJSON, ...myCompanyJSON, ...placesJSON};
const localeData = {...woLocaleData, ...enLocaleData};

render(
    createElement(App, {locale: 'en', localeData: localeData}),
    window.document.getElementById('root')
);
/*
 * Service worker
 ===================================================================================*/
serviceWorker.unregister();
