import React from "react";
import { LoaderLarge } from "../../loader";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import Card from "../../card";
import FieldsToHtml from "../../fields/fields-to-html";
import {classNames} from "../../../util/util-helpers";

export default function FormCard({title, strapLine, fields, onInputChange, selects, fieldLabelType, addFieldContainerClass, includedFields, excludedFields, contentBefore, contentAfter, isLoading, innerRef, translate, isExpanded, toggleExpandSection}) {

    return (
        <Card
            innerRef={innerRef}
            addClass="lg:col-span-9"
            addBodyClass="space-y-6 relative"
        >
            <div>
                <h3 className="text-lg font-medium leading-6 text-tm-gray-900 flex items-center">
                    <button
                    className="btn btn-icon -ml-2 md mr-2 w-8 h-8"
                    onClick={() => toggleExpandSection()}
                >
                    <ChevronRightIcon
                        className={
                            classNames(
                                "w-8 h-8",
                                isExpanded ? "rotate-90" : undefined
                            )
                        }
                    />
                </button>
                    {title}
                </h3>

                {!!strapLine && (
                    <div className="mt-1 text-sm text-tm-gray-500">
                        {strapLine}
                    </div>
                )}
            </div>

            {contentBefore}

            {!!isExpanded && (
                <div className="grid grid-cols-12 gap-4">
                    <FieldsToHtml
                        fieldsState={fields}
                        onInputChange={onInputChange}
                        selects={selects}
                        fieldLabelType={fieldLabelType}
                        addFieldContainerClass={addFieldContainerClass}
                        includedFields={includedFields}
                        excludedFields={excludedFields}
                        translate={translate}
                    />
                </div>
            )}

            {contentAfter}

            {isLoading && (
                <LoaderLarge stripesBg={true}/>
            )}
        </Card>
    )
}