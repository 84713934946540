import React, {useRef, useState} from "react";
import Resources from "../../../../data/services/resources";
import Buttons from "../../../../common/components/buttons";
import {ArrowLeftIcon, MinusIcon, PlusIcon} from "@heroicons/react/24/outline";
import FormCard from "../../../../common/components/forms/form-card";
import {groupListBySCAC} from "../../../../common/util/util-helpers";
import InfoParagraph from "../../../../common/components/info-paragraph";

export default function RentalsCreateInfoTab({
                                                 translate,
                                                 fieldsBasic,
                                                 fieldsAssets,
                                                 handleBasicInputChange,
                                                 handleAssetsInputChange,
                                                 isLayoutScrolled,
                                                 scrollToTop,
                                                 history,
                                                 existingRentalWarning,
                                                 existingRentalID
                                             }) {

    const basicInfoRef = useRef();
    const assetsRef = useRef();
    const [rentalsSideMenu, setRentalsSideMenu] = useState(
        [
            {
                key: "basicInfo",
                label: translate('text.basicInfo'),
                ref: basicInfoRef,
                expanded: true
            },
            {
                key: "assets",
                label: translate('text.assets'),
                ref: assetsRef,
                expanded: true
            },
        ])


    /** Helpers
     ================================================================= */
    const handleScrollToSectionClick = (sectionRef) => {
        sectionRef.current.scrollIntoView({block: "center", behavior: "smooth"})
    }

    const toggleExpandSection = (sectionName) => {
        let sections = Object.values(rentalsSideMenu)

        sections.map(it => {
            if (sectionName === it.key) {
                it.expanded = !it.expanded;
            }
            return it;
        })

        setRentalsSideMenu(sections)

    }
    /** Lifecycle
     ================================================================= */

    /** Component Body
     ================================================================= */
    return (
        <div>
            <div className="xl:max-w-3xl md:ml-44 mx-6 xl:mx-auto py-8 space-y-6 relative mb-64">
                <div
                    className="hidden w-36 h-full absolute md:block right-full ml-6">
                    <div className="fixed h-10 top-30">
                        <div
                            className="space-y-1 pr-6"
                        >
                            {isLayoutScrolled && (
                                <div className="flex relative right-4">
                                    <div>
                                        <Buttons
                                            buttons={[
                                                {
                                                    iconLeading: ArrowLeftIcon,
                                                    className: "btn btn-header",
                                                    onClick: () => history.push('/rentals/'),
                                                    title: "Go back to rentals list"
                                                }
                                            ]}
                                        />
                                    </div>

                                    <button
                                        onClick={() => scrollToTop()}
                                        className="btn btn-text ml-2 hover:bg-primary-transparent hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                                    >
                                        {translate("text.scroll_to_top")}
                                    </button>
                                </div>
                            )}
                            {rentalsSideMenu.map(it => {
                                return (<div className={"flex relative right-4"}>
                                        <button
                                            className="flex btn-icon rounded-button hover:bg-tm-gray-200"
                                            onClick={() => toggleExpandSection(it.key)}
                                        >
                                            {!it.expanded && (
                                                <PlusIcon className="w-5 h-5 text-green-600"/>
                                            )}

                                            {it.expanded && (
                                                <MinusIcon className="w-5 h-5"/>
                                            )}
                                        </button>

                                        <button
                                            onClick={() => handleScrollToSectionClick(it.ref)}
                                            className="text-tm-gray-800 hover:bg-inverse flex items-center px-3 py-2 text-sm font-medium rounded-btn w-full"
                                        >
                                            <span className="truncate">{it.label}</span>
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className={"xl:max-w-3xl space-y-8 col-span-12 lg:col-span-10"}>
                    <FormCard
                        innerRef={basicInfoRef}
                        title={translate('text.basicInfo')}
                        onInputChange={handleBasicInputChange}
                        fields={fieldsBasic}
                        selects={getSelects()}
                        addFieldContainerClass={"col-span-6"}
                        translate={translate}
                        isExpanded={rentalsSideMenu[0].expanded}
                        toggleExpandSection={() => toggleExpandSection('basicInfo')}
                    />

                    <FormCard
                        innerRef={assetsRef}
                        title={translate('text.assets')}
                        onInputChange={handleAssetsInputChange}
                        fields={fieldsAssets}
                        selects={getSelects()}
                        addFieldContainerClass={"col-span-6"}
                        translate={translate}
                        isExpanded={rentalsSideMenu[1].expanded}
                        toggleExpandSection={() => toggleExpandSection('assets')}
                        contentBefore={existingRentalWarning ? (
                            <a href={`/rentals/${existingRentalID}`} target="_blank">
                                <InfoParagraph
                                    className={"max-w-fit"}
                                    type="danger"
                                    message={

                                        <p>{translate("text.existingRentalWarning")}
                                        </p>
                                    }
                                />
                            </a>
                        ) : null}
                    />
                </div>
            </div>
        </div>
    )
}

function getSelects() {
    return {
        RentTypeID: {
            1: 'Rent',
            2: 'Lease'
        },
        PayContactID: {
            api: 'api/' + Resources.Contacts,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
                metadata: it
            })
        },
        PayOrganizationID: {
            api: 'api/' + Resources.Organizations,
            query: {},
            searchMap: (it) => ({
                label: it.LegalName,
                value: it.OrganizationID,
                metadata: it
            })
        },
        RentContactID: {
            api: 'api/' + Resources.Contacts,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
                metadata: it
            })
        },
        RentOrganizationID: {
            api: 'api/' + Resources.Organizations,
            query: {},
            searchMap: (it) => ({
                label: it.LegalName,
                value: it.OrganizationID,
                metadata: it
            })
        },
        TruckID: {
            api: 'api/' + Resources.Trucks,
            query: {
                NotSoldRetired: 1
            },
            customizeList: (list) => {
                return groupListBySCAC(list, 'Truck')
            }
        },
        TrailerID: {
            api: 'api/' + Resources.Trailers,
            query: {
                NotSoldRetired: 1
            },
            customizeList: (list) => {
                return groupListBySCAC(list, 'Trailer')
            }
        },
        RentalContractID: {
            api: 'api/' + Resources.WorkOrdersRentalContracts,
            query: {},
            searchMap: (it) => ({
                label: it.RefNumber,
                value: it.RentalContractID,
                metadata: it
            })
        },
    }
}

