import React, {Component} from 'react'
import {TrashIcon} from "@heroicons/react/24/outline";
import {Field, FieldsManager} from "../../../../data/services/fields";
import ResourceTable from "../../resource-table";
import ModalConfirm from "../../modal/modal-confirm";
import ModalDefault from "../../modal/modal-default";
import icons from "../../../../data/themes/icons";
import {DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS} from "../../../../util/util-constants";
import PageHeader from "../../layout/layout-components/page/page-header";
import {classNames, getLookup, getProp, longTableColumn} from "../../../util/util-helpers";
import {fieldsToHtml} from "../../../util/util-fields";
import DropZone from "../../../../common/components/dropzone/drop-zone-alt";
import Card from "../../card";
import {DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS} from "../../../util/util-consts";

export default class ImagesCreateTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchFields: this.props.searchFields ?? {},

            tableFields: this.getTableFields(),

            files: [],

            selectedItem: null,
            filesUploadDialog: false,
            filesUploadDialogHideAnimation: false,
            createModalOpen: false,
            confirmModalOpen: false,

            canSubmit: false
        };
    }

    /** UI Events
     ================================================================= */
    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleCancelUpload = () => {
        this.setState({
            uploadFilesModalOpen: false,
            files: [],
        });
    }

    handleRemoveDocumentFromUpload = (index) => {
        let files = this.state.files
        files.splice(index, 1)
        if (files.length === 0) {
            this.handleCancelUpload()
        } else {
            this.setState({files})
        }
    }

    handleInputChange = (name, value, i) => {
        const files = [...this.state.files]
        files[i].fields = FieldsManager.updateField(files[i].fields, name, value)

        this.setState({
            files,
            canSubmit: true
        })
    };

    handleUploadDocument = () => {
        this.setState({
            files: this.state.files.map((file) => Object.assign(file, {
                fields: FieldsManager.validateFields(file.fields)
            }))
        }, () => {
            if (this.state.files.reduce((memo, file) => (memo && FieldsManager.checkFieldsForErrors(file.fields)), true)) {
                const files = this.state.files.map(file => Object.assign(file, {
                    params: FieldsManager.getFieldKeyValues(file.fields)
                }))
                this.props.handleUploadDocument(files)
                this.handleCancelUpload()
            }
        })
    }

    /** Helpers
     ================================================================= */
    getDocumentUploadFields = () => {
        if (this.props.documentUploadFields) {
            return Object.assign({}, this.props.documentUploadFields())
        } else {
            return {
                ImageTypeID: new Field("ImageTypeID", '', ['empty'], false, 'select'),
                Description: new Field("Description", '', [], false, 'textarea'),
            }
        }
    }

    getTableFields = () => {
        return Object.assign({}, {
            DocumentName: new Field('DocumentName', '', [''], false),
            Description: new Field("Description", '', [], false, 'textarea'),
            ImageTypeID: new Field('ImageTypeID', '', [''], false, 'custom', {
                render: (item) => {
                    return (
                        ((Number(item.ImageTypeID) === 1) && <div>Before</div>) || ((Number(item.ImageTypeID) === 2) &&
                            <div>After</div>)
                    )
                },
                omitSort: true
            }),
    })}

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        });
    }

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    }

    onDrop = () => {
        this.setState({
            dropzoneActive: false,
            uploadFilesModalOpen: true
        });
    }

    handleDocumentDragEnter = () => {
        this.setState({
            isDocumentDragged: true
        })
    }

    handleDocumentDragStop = () => {
        this.setState({
            isDocumentDragged: false
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "fields", []).map(it => ({
            preview: it.preview,
            name: it.name,
            DocumentName: it.name,
            ...FieldsManager.getFieldKeyValues(it.fields),
        }))

        return (
            <div className="relative" onDragEnter={this.handleDocumentDragEnter}>
                <Card bodyClass={"py-3 px-6"} addClass="relative pb-7">
                    <PageHeader
                        title={translate("page.heading.Images")}
                        titleClass="mb-5 text-2xl"
                    />


                    <ResourceTable
                        data={data}

                        fields={this.state.tableFields}
                        translate={this.props.translate}


                        onDelete={this.handleToggleConfirmModal}

                        options={{style: {floatingActions: true, horizontalLines: true}}}

                    />

                    <DropZone
                        className={
                            classNames(
                                "absolute",
                                this.state.isDocumentDragged ? "inset-0 z-50" : "left-0 right-0 top-0 bottom-0"
                            )
                        }
                        maxFilesAccepted={10}
                        accept={DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS}
                        translate={translate}
                        onAcceptFiles={(acceptedFiles) => {
                            if (!!acceptedFiles?.length) {
                                this.setState({
                                    files: acceptedFiles.map(file => Object.assign(file, {
                                        preview: URL.createObjectURL(file),
                                        errorMessage: '',
                                        fields: Object.assign({}, this.getDocumentUploadFields())
                                    })),
                                    uploadFilesModalOpen: true,
                                    canSubmit: true,
                                    isDocumentDragged: false
                                });
                            }
                        }}
                        content={(isDragAccept, isDragReject) => {
                            return (
                                <React.Fragment>
                                    <div
                                        onDragLeave={this.handleDocumentDragStop}
                                        className={classNames(
                                            isDragAccept ? "border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10" : undefined,
                                            isDragReject ? " border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10" : undefined,
                                            !isDragAccept && !isDragReject ? "border-transparent" : undefined,
                                            "pb-4 absolute inset-0 rounded-card"
                                        )}>

                                    </div>

                                    <div
                                        className="absolute bottom-0 left-0 right-0 px-6 cursor-pointer py-1 border-t border-primary border-dotted bg-primary-transparent">
                                        Attach files by dragging and dropping them or
                                        <span className="text-primary-tint font-semibold"
                                              ref={this.DropZoneClickRef}>{" "} click here</span> to
                                        select them.
                                    </div>
                                </React.Fragment>
                            )
                        }}
                    />
                </Card>

                <ModalConfirm
                    title={"Confirm"}
                    show={!!this.state.confirmModalOpen}
                    text={translate("text.confirm_delete_image")}
                    onClose={() => this.setState({confirmModalOpen: false})}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={translate('btn.cancel')}
                    translate={translate}
                    onConfirm={() => {
                        this.props.handleDeleteDocument(this.state.selectedItem)
                        this.handleToggleConfirmModal(false)
                    }}
                />

                <ModalDefault
                    show={this.state.uploadFilesModalOpen}
                    widthClass={'max-w-5xl'}
                    title={translate("modal_heading.upload_confirm")}

                    close={this.handleCancelUpload}
                    closeButtonLabel={translate('Close')}
                    onClose={this.handleCancelUpload}

                    buttonDisabled={!this.state.canSubmit}
                    buttonLabel={translate('Upload')}
                    onButtonClick={this.handleUploadDocument}
                >
                    <div className="p-5">
                        {this.state.files.map((it, i) => {
                            const itemToHtml = fieldsToHtml(Object.values(Object.assign({}, it.fields)), translate, (name, value) => this.handleInputChange(name, value, i), {
                                ImageTypeID:
                                    {
                                        1: "Before",
                                        2: "After"
                                    }
                            })

                            return (
                                <div key={i}>
                                    {!!i && (
                                        <div className={'border-b border-tm-gray-300 mb-4 p-4 -ml-4 -mr-4'}/>
                                    )}

                                    <div className="flex justify-between">
                                        <div
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 break-all">
                                            <label>{translate("text.desc_for_file")}: {this.state.files[i].name}</label>
                                        </div>
                                        <div className="text-right">
                                            <button
                                                className="btn btn-icon"
                                                onClick={() => this.handleRemoveDocumentFromUpload(i)}
                                            >
                                                <TrashIcon className="h-4 w-4"/>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="grid gap-4">
                                        {itemToHtml}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </ModalDefault>
            </div>
        )
    }
}
