import React from 'react';
import {classNames} from "../../../../util/util-helpers";

const StatusProgressTable = ({RentalStatusID, translate, htmlAfterStatus}) => {

    if (!RentalStatusID) {
        return null;
    }

    const getStatus = () => {
        switch (+RentalStatusID) {
            case 1:
                return "Booking Requested"
            case 2:
                return "Approval Pending"
            case 3:
                return "Approved"
            case 4:
                return "Equipment Ready"
            case 5:
                return "Rental / Lease Active"
            case 6:
                return "Completed"
            case undefined:
                return translate("text.canceled")
            default:
                return translate("text.canceled")
        }
    }


    const getBadgeClass = () => {
        switch (+RentalStatusID) {
            case 1:
                return "bg-tm-gray-300 text-tm-gray-900"
            case 2:
                return "bg-orange-600 text-white"
            case 3:
                return "bg-yellow-300 text-black"
            case 4:
                return "bg-yellow-600 text-white"
            case 5:
                return "bg-green-600 text-white"
            case 6:
                return "bg-blue-600 text-white"
            default:
                return "bg-gray-600/10 text-tm-gray-600"
        }
    }

    return (
        <div
            className={classNames("leading-5 inline-block px-3 rounded-md text-sm font-semibold", getBadgeClass())}
        >
            {getStatus()} {htmlAfterStatus}
        </div>
    )
}

export default StatusProgressTable;
