import React from 'react'
import Card from "../../card";
import {ArrowDownTrayIcon, EyeIcon, EnvelopeIcon, TrashIcon} from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import {LoaderSmall} from "../../loader";
import Env from "../../../../util/env";
import Resources from "../../../../data/services/resources";
import {getJWT, getUser} from "../../../util/util-auth";


const ImagesSplitView = ({
                                data,
                                disableDelete,
                                disableSendViaEmail,
                                translate,
                                isLoading,
                                onToggleViewDocument,
                                onToggleSendEmailDialog,
                                onToggleConfirmModal,
                                onDownloadDocument,
                                getSingleResourceName
                            }) => {

    const dataBefore = data.filter((it) => it.ImageTypeID === 1)
    const dataAfter = data.filter((it) => it.ImageTypeID === 2)
    const user = getUser();

    const ImagesItems = (data, type) => {

        return data.map((item, index) => {
            return (
                <Card addClass={"my-5"}>
                    <div onClick={() => onToggleViewDocument(item)} className={'cursor-pointer'}>
                        <div className={'grid mx-auto mb-10 text-primary h-[200px] justify-items-center items-center'}>
                            <img
                                className="inline-block ring-4 ring-white max-h-[200px] max-w-[calc(70%)]"
                                alt={"image"}
                                src={Env.getApiUrl("api/" + getSingleResourceName(), {
                                    ImageID: item.ImageID,
                                    token: getJWT().access_token,
                                    type: 1
                                })}
                                onLoad={() => null}
                                onError={({currentTarget}) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/images/brokenImage.png";
                                }}
                            />

                        </div>
                        <Tippy
                            content={<span className={'break-all'}>{item.DocumentName}</span>}
                        >
                            <h3 className="text-sm leading-6 font-black text-primary text-center truncate">{item.DocumentName}</h3>
                        </Tippy>
                    </div>
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-tm-gray-300"/>
                        </div>
                        <div className="relative flex justify-center">
                        <span className="relative z-0 inline-flex shadow-sm rounded-md -space-x-px">
                            <Tippy
                                content={translate("btn.quick_view")}
                            >
                                <button
                                    onClick={() => onToggleViewDocument(item)}
                                    className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-inverse text-sm font-medium text-gray-400 hover:bg-tm-gray-300 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                >
                                    <EyeIcon className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </Tippy>
                            {!disableSendViaEmail && (
                                <Tippy
                                    content={translate("btn.send_document_via_email")}
                                >
                                    <button
                                        onClick={() => onToggleSendEmailDialog(item)}
                                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-inverse text-sm font-medium text-gray-400 hover:bg-tm-gray-300 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                    >
                                        <EnvelopeIcon className="h-5 w-5" aria-hidden="true"/>
                                    </button>
                                </Tippy>
                            )}
                            {!disableDelete && (
                                <Tippy
                                    content={translate("btn.delete")}
                                >
                                    <button
                                        onClick={() => onToggleConfirmModal(item)}
                                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-inverse text-sm font-medium text-gray-400 hover:bg-tm-gray-300 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                    >
                                        <TrashIcon className="h-5 w-5" aria-hidden="true"/>
                                    </button>
                                </Tippy>
                            )}
                            <Tippy
                                content={translate("btn.Download")}
                            >
                                <button
                                    onClick={() => onDownloadDocument(item)}
                                    className="relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-inverse text-sm font-medium text-gray-400 hover:bg-tm-gray-300 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                >
                                    <ArrowDownTrayIcon className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </Tippy>
                        </span>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 divide-x divide-tm-gray-300 border-tm-gray-300 border-t">
                        <div className={'pt-2'}>
                            <h3 className="text-sm leading-6 font-black text-primary truncate mb-2">Updated
                                By: </h3>
                            <span
                                className={'px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full'}>
                            {item.UpdatedByContact ? item.UpdatedByContact : translate("text.system")}
                        </span>
                        </div>

                        <div className={'text-right pt-2'}>
                            <h3 className="text-sm leading-6 font-black text-primary truncate mb-2">Image type: </h3>
                            <span>{type === 1 ? "Before" : "After"}</span>
                        </div>
                    </div>
                </Card>
            )
        })
    }

    return (
        isLoading ?
            <div className="p-5 text-center">
                <LoaderSmall/>
            </div>
            :
            data.length > 0 && (
                <div className={'grid grid-cols-6'}
                    // style={{gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))"}}>
                    >

                    <div className={"col-span-3 pr-3"}>
                        <div className={"text-center text-lg text-primary"}>Before</div>
                        {ImagesItems(dataBefore, 1)}
                    </div>

                    <div className={"col-span-3 pl-3"}>
                        <div className={"text-center text-lg text-primary"}>After</div>
                        {ImagesItems(dataAfter, 2)}
                    </div>

                </div>
            )
    )
}

export default ImagesSplitView
