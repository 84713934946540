import React, {useEffect, useRef, useState} from "react";
import Resources from "../../../data/services/resources";
import RentalContractsSingleInfoTab from "./rental-contracts-single-info-tab";
import RentalContractsSingleDocumentsTab from "./rental-contracts-single-documents-tab";
import RentalContractsSingleAnnexTab from "./rental-contracts-single-annex-tab";
import {READ_PERM} from "../../../common/util/util-consts";
import Layout from "../../../common/components/layout";
import NavResponsive from "../../../common/components/nav-responsive";
import {LoaderSmall} from "../../../common/components/loader";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import {ArrowLeftIcon} from "@heroicons/react/24/outline";
import {checkPerm, getProp} from "../../../common/util/util-helpers";
import {getResource} from "../../../data/actions/resource";
import {getUser} from "../../../common/util/util-auth";
import {useDispatch, useSelector} from "react-redux";
import {getSecondResource} from "../../../data/actions/secondResource";

export default function RentalContractsSingleView({history, match, translate }) {

    const uploadDocumentButtonRef = useRef();

    /** State
     ================================================================= */
    const [tabs, setTabs] = useState(getTabs());
    const currentTab = tabs.find(it => it.current);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [rentalContractInfo, setRentalContractInfo] = useState({})
    const secondResource = useSelector((state) => state.secondResource);
    const data = getProp(secondResource, 'data.list', {});
    const dispatch = useDispatch();
    const isLoading = getProp(secondResource, 'isLoading', false)


    let buttons = [];

    if (currentTab?.name === "Addendum") {
        buttons = [{
            className: "btn btn-primary",
            label: "Create addendum",
            onClick: () => setIsCreateDialogOpen(true)
            }];
    }

    if (currentTab?.name === "Documents") {
        buttons = [{
            className: "btn btn-primary",
            label: "Upload document",
            onClick: () => uploadDocumentButtonRef?.current && uploadDocumentButtonRef.current.click()
        }];
    }

    /** UI Events
     ================================================================= */
    const handleTabChange = (resource) => {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        )
    }
    const fetchData = (dispatch, resourcePath, ID) => {
        dispatch(getSecondResource({
            user: getUser(),
            query: {
                id: ID
            },
            resource: resourcePath,
        }))
    }

    useEffect(() => {
        if (!isLoading && currentTab.resource === Resources.WorkOrdersRentalContracts) {
            setRentalContractInfo(data[0])
        }
    }, [isLoading])

    /** Helper functions
     ================================================================= */
    function getTabs() {
        return [
            {
                name: 'Info',
                resource: Resources.WorkOrdersRentalContracts,
                current: true,
                visible: checkPerm(Resources.WorkOrdersRentalContracts, READ_PERM)
            },
            {
                name: 'Addendum',
                resource: Resources.WorkOrdersRentalContractsAnnex,
                current: false,
                visible: checkPerm(Resources.WorkOrdersRentalContractsAnnex, READ_PERM)
            },
            {
                name: 'Documents',
                resource: Resources.WorkOrdersRentalContractsDocuments,
                current: false,
                visible: checkPerm(Resources.WorkOrdersRentalContractsDocuments, READ_PERM)
            }
        ]
    }
    /** Component Body
     ================================================================= */
    return (
        <Layout
            history={history}
            match={match}
            translate={translate}
        >
            <PageHeader
                title={
                    <span className="flex items-center">
                        {translate("page_title.rental-contracts")}
                        <span className="ml-1 text-tm-gray-600 flex">
                             - {isLoading ? <LoaderSmall disableContainer={true} addClass={"relative top-2"}/> : <span className={"mx-1"}>{rentalContractInfo?.AutoCounter}</span>}
                        </span>
                    </span>
                }
                buttons={buttons}
                ButtonsLeading={[
                    {
                        iconLeading: ArrowLeftIcon,
                        className: "btn btn-header m-2",
                        onClick: () => history.push('/rental-contracts/'),
                        title: "Go back to rental contracts"
                    }
                ]}
            />

            <NavResponsive
                addClass="px-4 sm:px-6 md:px-8 h-9"
                tabs={tabs}
                onTabChange={handleTabChange}
                translate={translate}
            />

            {currentTab.name === "Info" && (
                <RentalContractsSingleInfoTab
                    id={match.params.id}
                    history={history}
                    match={match}
                    translate={translate}
                    fetchData={() => fetchData(dispatch, currentTab.resource, match.params.id)}
                />
            )}

            {currentTab.name === "Addendum" && (
                <RentalContractsSingleAnnexTab
                    id={match.params.id}
                    isCreateDialogOpen={isCreateDialogOpen}
                    setIsCreateDialogOpen={setIsCreateDialogOpen}
                    history={history}
                    match={match}
                    translate={translate}
                />
            )}

            {currentTab.name === "Documents" && (
                <RentalContractsSingleDocumentsTab
                    id={match.params.id}
                    history={history}
                    match={match}
                    uploadDocumentButtonRef={uploadDocumentButtonRef}
                    translate={translate}
                />
            )}
        </Layout>
    )
}