import {Field, FieldsManager} from "../../../../../data/services/fields";
import Resources from "../../../../../data/services/resources";
import LocalStorage from "../../../../../util/localStorage";
import EmailDocumentDialog from "../../../../../common/components/modal/modal-email-documents/modal-email-document";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createResource} from "../../../../../data/actions/resource";
import {download} from "../../../../../data/actions/download";
import {currentDate} from "../../../../../common/util/util-dates";
import Env from "../../../../../util/env";
import {getJWT} from "../../../../../common/util/util-auth";
import {fillFieldsFromData} from "../../../../../common/util/util-fields";

export default function EmailEstimate({show, onClose, WorkOrder, selectedItem, ID, translate}) {
    const dispatch = useDispatch();
    const thirdResource = useSelector(state => state.thirdResource);
    const [documentSettingsFields, setDocumentSettingsFields] = useState(() => getDocumentSettingsFields());
    const [woAutoCount, setWoAutoCount] = useState("");
    function handleDownloadDocument(markSent = false) {

        dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.WorkOrderEstimate,
            query: Object.assign(ID ? {WorkOrderID: ID} : {}, {
                id: ID,
                name: "estimate_" + ID + '_' + currentDate() + '.pdf',
                MarkAsSent: markSent ? 1 : 0,
            }),
            errorMessage: true,
            successMessage: 'Successfully downloaded document!'
        }))
    }

    function handleDocumentSettingsInputChange(name, value) {
        setDocumentSettingsFields(
            FieldsManager.updateField(documentSettingsFields, name, value)
        )
    }

    function getDocumentSettingsFields() {
        return {
            DigitalSign: new Field("DigitalSign", 1, [''], false, 'button-group', {
                data: {0: translate('btn.no'), 1: translate('btn.yes')},
                addContainerClass: 'col-span-full',
                labelType: 'left',
                note: 'Document will be sent for digital signing to the entered email addresses. Once signed confirmation email will be sent to both parties.'
            })
        }
    }

    function getEmailFields() {
        const fieldsTemplate = {
            EmailTemplateID: new Field('EmailTemplateID', '', [''], false, 'select-search', {addContainerClass: 'md:col-span-6'}),
            emails: new Field('emails',  "", ['empty'], false, 'creatable-csv', {
            addContainerClass: 'col-span-8',
        }, {
            placeholder: 'Type email...',
            addClass: "rounded-r-none form-control"
        }),
            AddContactID: new Field('AddContactID', '', [''], false, 'select-search', {addContainerClass: 'col-span-4 -ml-px'}, {
            placeholder: 'Filter contacts...',
            addClass: "form-control rounded-l-none"
        }),
            subject: new Field('subject', 'Estimate for #WO-' + woAutoCount, ['empty'], false, 'text', {addContainerClass: 'col-span-full'}),
            emailMessage: new Field('emailMessage', '', [], false, 'rich-text', {
            addContainerClass: 'col-span-full'
        }),
        }

        return fillFieldsFromData(fieldsTemplate, {emails: selectedItem?.DefaultEmail})
    }

    useEffect(() => {
        if (WorkOrder?.AutoCounter) {
            setWoAutoCount(WorkOrder.AutoCounter)
        }
    }, [WorkOrder]);

    return (
        <EmailDocumentDialog
            show={show}
            title={'Estimate for #WO-' + woAutoCount}
            widthClass="max-w-5xl"
            onClose={onClose}
            hideDocumentFields={true}
            primaryBtnLabel={translate('btn.send')}
            fields={getEmailFields()}
            onDocumentSettingsInputChange={handleDocumentSettingsInputChange}
            hasDocumentSetting={true}
            documentSettingsFields={documentSettingsFields}
            metadata={{
                AddContactID: {
                    api: 'api/' + Resources.ContactsQuick,
                    query: {offset: 0, sort: 'ASC', limit: 10},
                    searchMap: (item) => ({
                        value: item.Email,
                        label: item.FirstName + ' ' + item.LastName
                    })
                },
                EmailTemplateID: {
                    api: 'api/' + Resources.EmailTemplates,
                    query: {offset: 0, sort: 'ASC', limit: 10},
                    searchMap: (item) => ({
                        value: item.EmailTemplateID,
                        label: item.EmailTemplateName
                    })
                }
            }}

            onSubmit={(params) => {
                if (params) {

                    // Add document settings parameters
                    params = Object.assign(params, FieldsManager.getFieldKeyValues(documentSettingsFields));

                    params.Emails = params.emails;

                    delete params.AddContactID;
                    delete params.emails;
                    delete params.EmailTemplateID;

                    dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign(params, {
                            WorkOrderID: ID,
                        }),
                        resource: Resources.WorkOrderEstimate,
                        errorMessage: true,
                        successMessage: 'Document sent successfully!'
                    }))

                    onClose();
                }
            }}

            onPrint={() => {
                handleDownloadDocument();
            }}
            
            fileType={'pdf'}
            filePath={Env.getApiUrl(
                'api/' + Resources.WorkOrderEstimate,
                Object.assign(ID ? {WorkOrderID: ID} : {},
                    {
                        token: getJWT().access_token,
                        id: ID,
                        name: "estimate_" + ID + '_' + currentDate() + '.pdf',
                    }
                ))
            }
            translate={translate}
            dispatch={dispatch}
            thirdResource={thirdResource}
        />
    )
}