export const _ENV_MODE = process.env.REACT_APP_ENV_MODE;

export default class Env {
    static getTruckHrUrl(path) {
        switch (process.env.REACT_APP_ENV_MODE) {
            case "dev":
                return "https://dev-hr.accur8.services" + path;
            case "test":
                return "https://test-hr.accur8.services" + path;
            case "stage":
                return "https://stage-hr.accur8.services" + path;
            case "prod":
                return "https://hr.accur8.services" + path;
            default:
                return "http://hr.tms.localdomain:3002" + path;
        }
    }

    static getTruckWorkOrdersUrl(path) {
        switch (process.env.REACT_APP_ENV_MODE) {
            case "local":
                return "http://localhost:3001" + path;
            case "dev":
                return "https://dev-wo.accur8.services" + path;
            case "test":
                return "https://test-wo.accur8.services" + path;
            case "stage":
                return "https://stage-wo.accur8.services" + path;
            case "prod":
                return "https://wo.accur8.services" + path;
            default:
                return "http://wo.tms.localdomain:3001" + path;
        }
    }

    static getTruckTrackingUrl(path) {
        switch (process.env.REACT_APP_ENV_MODE) {
            case "local":
                return "http://localhost:3005" + path;
            case "dev":
                return "https://dev-tracking.accur8.services" + path;
            case "test":
                return "https://test-tracking.accur8.services" + path;
            case "stage":
                return "https://stage-tracking.accur8.services" + path;
            case "prod":
                return "https://tracking.accur8.services" + path;
            default:
                return "http://tracking.tms.localdomain:3005" + path;
        }
    }

    static getTruckRentalUrl(path) {
        switch (process.env.REACT_APP_ENV_MODE) {
            case "local":
                return "http://localhost:3006" + path;
            case "dev":
                return "https://dev-rental.accur8.services" + path;
            case "test":
                return "https://test-rental.accur8.services" + path;
            case "stage":
                return "https://stage-rental.accur8.services" + path;
            case "prod":
                return "https://rental.accur8.services" + path;
            default:
                return "http://rental.tms.localdomain:3006" + path;
        }
    }

    static getTruckMainUrl(path) {
        switch (process.env.REACT_APP_ENV_MODE) {
            case "local":
                return "http://localhost:3000" + path;
            case "dev":
                return "https://dev.accur8.services" + path;
            case "test":
                return "https://test.accur8.services" + path;
            case "stage":
                return "https://stage.accur8.services" + path;
            case "prod":
                return "https://tms.accur8.services" + path;
            default:
                return "http://main.tms.localdomain:3000" + path;
        }
    }

    static getApiUrl(path, query = null) {
        return process.env.REACT_APP_API_URL+ path + Env.addQuery(query);
    }

    static getWorkOrderAiUrl(path, query = null) {
        return "https://temp-dev.accur8.services/api/ai-work-orders/" + path + Env.addQuery(query);
    }

    static addQuery(path) {
        if (!!path) {
            let result = ('?' +
                (
                    Object.keys(path).reduce(
                        (memo, key) => {
                            if (Array.isArray(path[key])) {
                                memo += (path[key].map((it) => {
                                    return key + "[]=" + encodeURIComponent(it)
                                })).join("&") + "&";
                            } else {
                                memo += (key + "=" + encodeURIComponent(path[key]) + "&");
                            }
                            return memo;
                        }
                        , "")
                )
            );
            return result.substring(0, result.length - 1);
        }
        return "";
    }

    static getPublicToken() {
        return 'dGVzdGNsaWVudDp0ZXN0cGFzcw=';
    }

    static getNpmPackageVersion() {
        // if version is missing add "REACT_APP_VERSION=$npm_package_version" to the .env file
        return process.env.REACT_APP_VERSION;
    }
}